const updateLoginEmail = email => ({
    type: 'UPDATE_LOGIN_EMAIL',
    email
});

const updateLoginNotice = notice => ({
    type: 'UPDATE_LOGIN_NOTICE',
    notice
});

const actions = {
    updateLoginEmail,
    updateLoginNotice
};

export default actions;
