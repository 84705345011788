import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import SignupLayout from '../components/SignupLayout.jsx';

import actions from '../actions.js';

/** Redux Container **/
const mapStateToProps = state => {
    const { invitation } = state.signup;

    return {
        invitation
    };
};

const mapDispatchToProps = dispatch => {
    const { updateInviteData, submitPaymentPlan } = actions;

    return bindActionCreators(
        {
            updateInviteData,
            submitPaymentPlan
        },
        dispatch
    );
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SignupLayout)
);
