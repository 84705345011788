import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import core from 'modules/core';
const { Header, Input, FormNavigation, Checkbox } = core.components;
const { getFormattedPhoneNumber } = core.helpers;

function validateName(name) {
    if (!name) {
        return 'The name field is required';
    } else if (name.trim().split(' ').length < 2) {
        return 'Both a first and last name are required';
    }
}

function validateAgencyName(name) {
    if (!name) {
        return 'The company name field is required';
    }
}

function validateChatbotName(name) {
    if (!name) {
        return 'The chatbot name field is required';
    }
}

function validateEmail(email) {
    if (!email) {
        return 'The email field is required';
    } else {
        let re = /\S+@\S+\.\S+/;

        if (!re.test(email)) {
            return 'The email is invalid, please use a valid email address.';
        }
    }
}

function validatePhone(phone) {
    if (!phone) {
        return 'The phone field is required';
    } else if (phone.replace(/\D/g, '').length !== 10) {
        return 'The phone field must contain a 10 digit code representing a phone number';
    }
}

function validatePassword(password) {
    const minPasswordLength = 8;

    if (!password) {
        return 'The password field is required';
    } else if (password.length < minPasswordLength) {
        return `Your password must be at least ${minPasswordLength} characters long`;
    }
}

function useInputState(initialValue) {
    const [value, setValue] = useState(initialValue);

    const onChange = e => {
        setValue(e.target.value);
    };

    return [value, onChange];
}

function usePhoneState() {
    const [phone, setPhone] = useState('');

    const onChange = e => {
        const value = e.currentTarget.value;
        let phoneNumber = value.replace(/\D/g, '');

        if (value) {
            if (getFormattedPhoneNumber(phone).length > value.length) {
                phoneNumber = phone.substring(0, phone.length - 1);
            }

            if (phoneNumber.length > 10) {
                phoneNumber = phoneNumber.substring(0, 10);
            }

            setPhone(phoneNumber);
        }
    };

    return [phone, onChange];
}

export const ResellerSignupComponent = ({ onVerifyReseller, ...props }) => {
    const [formErrors, setFormErrors] = useState('');
    const [name, changeName] = useInputState(props.name);
    const [phone, changePhone] = usePhoneState();
    const [email, changeEmail] = useInputState(props.email);
    const [agencyName, changeAgencyName] = useInputState(props.agencyName);
    const [chatbotName, changeChatbotName] = useInputState('Aisa');
    const [password, changePassword] = useInputState('');
    const [realEstate, toggleRealEstate] = useState(false);
    const [mortgage, toggleMortgage] = useState(false);
    const [solar, toggleSolar] = useState(false);
    const [insurance, toggleInsurance] = useState(false);
    const [automotive, toggleAutomotive] = useState(false);
    const [homeService, toggleHomeService] = useState(false);
    const [financialServices, toggleFinancialServices] = useState(false);
    const [homeServices, toggleHomeServices] = useState(false);

    const hasDomain =
        realEstate ||
        mortgage ||
        solar ||
        insurance ||
        automotive ||
        homeService ||
        financialServices ||
        homeServices;

    const hasErrors =
        !!validateName(name) ||
        !!validatePhone(phone) ||
        !!validateEmail(email) ||
        !!validateAgencyName(agencyName) ||
        !!validateChatbotName(chatbotName) ||
        !!validatePassword(password) ||
        !hasDomain;

    useEffect(() => {
        if (!hasErrors) {
            setFormErrors('');
        } else if (hasDomain) {
            setFormErrors('Please fill out all fields');
        }
    }, [hasErrors, hasDomain]);

    const onSubmit = () => {
        if (hasErrors) {
            setFormErrors(
                `Please fill out all fields${
                    !hasDomain ? ' and select at least one industry' : ''
                }.`
            );
            console.error('There are fields that are not filled in');
            return;
        }

        onVerifyReseller({
            name,
            email,
            password,
            phone,
            agencyName,
            automotive,
            financialServices,
            homeService,
            insurance,
            mortgage,
            realEstate,
            solar,
            chatbotName
        });
    };

    return (
        <section className="details-section">
            <Header
                title="Hi! I’m Your AI Assistant"
                subtitle="I just need a few details to finish setting up your account."
            />
            <div className="form-wrapper">
                <div className="details-form form">
                    <Input
                        id="name-input"
                        label="Full Name"
                        value={name}
                        autocomplete="name"
                        onChange={changeName}
                        onEnter={onSubmit}
                        validator={validateName}
                        autoFocus={true}
                    />
                    <Input
                        id="email-input"
                        label="Work Email"
                        type="email"
                        value={email}
                        autocomplete="email"
                        onChange={changeEmail}
                        onEnter={onSubmit}
                        validator={validateEmail}
                    />
                    <Input
                        id="phone-input"
                        label="Phone Number"
                        type="phone"
                        autocomplete="tel"
                        value={getFormattedPhoneNumber(phone)}
                        onChange={changePhone}
                        onEnter={onSubmit}
                        validator={validatePhone}
                    />
                    <Input
                        id="password-input"
                        label="Password"
                        type="password"
                        value={password}
                        autocomplete="new-password"
                        onChange={changePassword}
                        onEnter={onSubmit}
                        validator={validatePassword}
                    />
                    <Input
                        id="agency-input"
                        label="Company Name"
                        value={agencyName}
                        autocomplete="organization"
                        onChange={changeAgencyName}
                        onEnter={onSubmit}
                        validator={validateAgencyName}
                    />
                    <Input
                        id="chatbot-name-input"
                        label="Chatbot Name"
                        value={chatbotName}
                        onChange={changeChatbotName}
                        onEnter={onSubmit}
                        validator={validateChatbotName}
                    />
                    <div className="checkbox-group">
                        <h4 className="checkbox-group-title">Industry</h4>
                        <div className="domains_group">
                            <div className="checkbox-wrapper">
                                <Checkbox
                                    label="Real Estate"
                                    checked={realEstate}
                                    onChange={() =>
                                        toggleRealEstate(!realEstate)
                                    }
                                />
                            </div>
                            <div className="checkbox-wrapper">
                                <Checkbox
                                    label="Mortgage"
                                    checked={mortgage}
                                    onChange={() => toggleMortgage(!mortgage)}
                                />
                            </div>
                            <div className="checkbox-wrapper">
                                <Checkbox
                                    label="Solar"
                                    checked={solar}
                                    onChange={() => toggleSolar(!solar)}
                                />
                            </div>
                            <div className="checkbox-wrapper">
                                <Checkbox
                                    label="Insurance"
                                    checked={insurance}
                                    onChange={() => toggleInsurance(!insurance)}
                                />
                            </div>
                            <div className="checkbox-wrapper">
                                <Checkbox
                                    label="Automotive"
                                    checked={automotive}
                                    onChange={() =>
                                        toggleAutomotive(!automotive)
                                    }
                                />
                            </div>
                            <div className="checkbox-wrapper">
                                <Checkbox
                                    label="Home Service"
                                    checked={homeService}
                                    onChange={() =>
                                        toggleHomeService(!homeService)
                                    }
                                />
                            </div>
                            <div className="checkbox-wrapper">
                                <Checkbox
                                    label="Financial Services"
                                    checked={financialServices}
                                    onChange={() =>
                                        toggleFinancialServices(
                                            !financialServices
                                        )
                                    }
                                />
                            </div>
                            <div className="checkbox-wrapper">
                                <Checkbox
                                    label="Home Services"
                                    checked={homeServices}
                                    onChange={() =>
                                        toggleHomeServices(!homeServices)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <FormNavigation
                    onSubmit={onSubmit}
                    btnText="Activate Account"
                />
                {!!formErrors && (
                    <span className="error" style={{ color: '#D8000C' }}>
                        {formErrors}
                    </span>
                )}
            </div>
        </section>
    );
};

ResellerSignupComponent.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    agencyName: PropTypes.string.isRequired,
    onVerifyReseller: PropTypes.func.isRequired
};
