import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ sm, lg }) => {
    let spinnerSize = '';
    if (sm) {
        spinnerSize = 'spinner-sm';
    } else if (lg) {
        spinnerSize = 'spinner-lg';
    }

    return (
        <span className="loader">
            <span className={`loader-spinner ${spinnerSize}`} />
        </span>
    );
};

Spinner.defaultProps = {
    sm: false,
    lg: false
};

Spinner.propTypes = {
    sm: PropTypes.bool,
    lg: PropTypes.bool
};

export default Spinner;
