import gql from 'graphql-tag';

const RESET_PASSWORD = gql`
    mutation($accessToken: String!, $newPassword: String!) {
        resetPassword(accessToken: $accessToken, newPassword: $newPassword) {
            status
        }
    }
`;

const SEND_PASSWORD_RESET = gql`
    mutation($email: String!) {
        sendResetPassword(email: $email) {
            status
        }
    }
`;

const mutations = {
    RESET_PASSWORD,
    SEND_PASSWORD_RESET
};

export default mutations;
