import { connect } from 'react-redux';
import { graphql, compose, withApollo } from 'react-apollo';

import RequestPasswordLayout from '../components/RequestPasswordLayout.jsx';

import mutations from '../../../mutations.js';

/** Redux Container **/
const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

/** GraphQL Container **/
const { SEND_PASSWORD_RESET } = mutations;

const withSendResetPassword = graphql(SEND_PASSWORD_RESET, {
    props: ({ mutate }) => ({
        sendResetPassword(email) {
            return mutate({
                variables: { email }
            });
        }
    })
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withApollo,
    withSendResetPassword
)(RequestPasswordLayout);
