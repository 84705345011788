import gql from 'graphql-tag';

export const MUTATE_VERIFY_AGENT_FOR_RESELLER = gql`
    mutation (
        $name: String!
        $email: String!
        $password: String!
        $phone: String!
        $accessToken: String!
        $agencyName: String!
        $chatbotName: String!
        $automotive: Boolean
        $financialServices: Boolean
        $homeServices: Boolean
        $insurance: Boolean
        $mortgage: Boolean
        $realEstate: Boolean
        $solar: Boolean
    ) {
        verifyResellerBrokerage(
            name: $name
            email: $email
            password: $password
            agencyName: $agencyName
            phone: $phone
            accessToken: $accessToken
            settings: {
                defaultChatbotName: $chatbotName
                chatbotAllowedDomains: {
                    automotive: $automotive
                    financialServices: $financialServices
                    homeServices: $homeServices
                    insurance: $insurance
                    mortgage: $mortgage
                    realEstate: $realEstate
                    solar: $solar
                }
            }
        ) {
            result
        }
    }
`;
