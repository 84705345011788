const initialState = {
    name: '',
    email: '',
    phone: '',
    password: '',
    agencyName: '',
    realEstate: false,
    mortgage: false,
    software: false,
    solar: false,
    insurance: false,
    travel: false,
    automotive: false,
    other: false,
    addons: []
};

const subscribe = (state = initialState, action) => {
    switch (action.type) {
        case 'SUBMIT_DETAILS':
            return {
                ...state,
                name: action.name,
                email: action.email,
                phone: action.phone,
                password: action.password,
                agencyName: action.agencyName,
                realEstate: action.realEstate,
                mortgage: action.mortgage,
                software: action.software,
                solar: action.solar,
                insurance: action.insurance,
                travel: action.travel,
                automotive: action.automotive,
                support: action.support,
                other: action.other
            };
        case 'SUBMIT_ADDONS':
            return {
                ...state,
                addons: action.addons
            };
        case 'CLEAN_SIGNUP':
            return {
                ...initialState
            };
        case 'SET_BILLING_CLIENT_REFERENCE_ID':
            return {
                ...state,
                billingClientReferenceId: action.billingClientReferenceId
            };
        default:
            return state;
    }
};

const reducer = {
    subscribe
};

export default reducer;
