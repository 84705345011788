import gql from 'graphql-tag';

const MUTATE_INTEGRATE_LION_DESK = gql`
    mutation(
        $agencyName: String!
        $defaultChatbotName: String!
        $officeLocation: String!
    ) {
        createLionDeskAgent(
            agencyName: $agencyName
            defaultChatbotName: $defaultChatbotName
            officeLocation: $officeLocation
        ) {
            status
        }
    }
`;

const mutations = {
    MUTATE_INTEGRATE_LION_DESK
};

export default mutations;
