import gql from 'graphql-tag';

const MUTATE_AGENT_FOR_TEAM = gql`
    mutation (
        $email: String!
        $password: String!
        $name: String!
        $phone: String!
        $accessToken: String!
        $teamId: ID!
        $agencyName: String!
        $chatbotName: String
        $realEstate: Boolean
        $mortgage: Boolean
    ) {
        createAgentForTeam(
            agent: {
                name: $name
                email: $email
                password: $password
                agencyName: $agencyName
                phone: $phone
                mlsProviders: []
                mlsNeeded: []
                settings: {
                    defaultChatbotName: $chatbotName
                    chatbotAllowedDomains: {
                        realEstate: $realEstate
                        mortgage: $mortgage
                    }
                }
            }
            accessToken: $accessToken
            id: $teamId
        ) {
            agentId: id
        }
    }
`;

const MUTATE_AGENT_FOR_BROKERAGE = gql`
    mutation (
        $email: String!
        $password: String!
        $name: String!
        $phone: String!
        $chatbotName: String
        $accessToken: String!
        $brokerageId: ID!
        $agencyName: String!
        $realEstate: Boolean
        $mortgage: Boolean
    ) {
        createAgentForBrokerage(
            agent: {
                name: $name
                email: $email
                password: $password
                agencyName: $agencyName
                phone: $phone
                mlsProviders: []
                mlsNeeded: []
                settings: {
                    defaultChatbotName: $chatbotName
                    chatbotAllowedDomains: {
                        realEstate: $realEstate
                        mortgage: $mortgage
                    }
                }
            }
            accessToken: $accessToken
            id: $brokerageId
        ) {
            agentId: id
        }
    }
`;

const MUTATE_TEAM_FOR_BROKERAGE = gql`
    mutation (
        $email: String!
        $password: String!
        $name: String!
        $teamName: String!
        $phone: String!
        $chatbotName: String
        $accessToken: String!
        $brokerageId: ID!
        $realEstate: Boolean
        $mortgage: Boolean
    ) {
        createTeamForBrokerage(
            agent: {
                name: $name
                email: $email
                password: $password
                agencyName: $teamName
                phone: $phone
                mlsProviders: []
                mlsNeeded: []
                settings: {
                    defaultChatbotName: $chatbotName
                    chatbotAllowedDomains: {
                        realEstate: $realEstate
                        mortgage: $mortgage
                    }
                }
            }
            team: {
                name: $teamName
                settings: {
                    defaultChatbotName: $chatbotName
                    chatbotAllowedDomains: {
                        realEstate: $realEstate
                        mortgage: $mortgage
                    }
                }
            }
            accessToken: $accessToken
            id: $brokerageId
        ) {
            agentId
            teamId
        }
    }
`;

const MUTATE_CREATE_AGENT = gql`
    mutation (
        $name: String!
        $email: String!
        $phone: String!
        $password: String!
        $agencyName: String!
        $plan: String
        $chatbotName: String
        $stripeToken: String!
        $useHatchIsaScripts: Boolean
        $realEstate: Boolean
        $mortgage: Boolean
    ) {
        createAgent(
            name: $name
            email: $email
            phone: $phone
            password: $password
            agencyName: $agencyName
            plan: $plan
            settings: {
                defaultChatbotName: $chatbotName
                chatbotAllowedDomains: {
                    realEstate: $realEstate
                    mortgage: $mortgage
                }
            }
            mlsProviders: []
            mlsNeeded: []
            stripeToken: $stripeToken
            useHatchIsaScripts: $useHatchIsaScripts
        ) {
            agentId: id
        }
    }
`;

const MUTATE_CREATE_TEAM = gql`
    mutation (
        $name: String!
        $email: String!
        $phone: String!
        $password: String!
        $teamName: String!
        $chatbotName: String
        $stripeToken: String!
        $useHatchIsaScripts: Boolean
        $realEstate: Boolean
        $mortgage: Boolean
    ) {
        createTeam(
            stripeToken: $stripeToken
            agent: {
                name: $name
                email: $email
                password: $password
                agencyName: $teamName
                phone: $phone
                mlsProviders: []
                mlsNeeded: []
                settings: {
                    defaultChatbotName: $chatbotName
                    chatbotAllowedDomains: {
                        realEstate: $realEstate
                        mortgage: $mortgage
                    }
                }
            }
            team: {
                name: $teamName
                settings: {
                    defaultChatbotName: $chatbotName
                    chatbotAllowedDomains: {
                        realEstate: $realEstate
                        mortgage: $mortgage
                    }
                }
            }
            useHatchIsaScripts: $useHatchIsaScripts
        ) {
            teamId
            agentId
            accessToken
        }
    }
`;

const MUTATE_CREATE_BROKERAGE = gql`
    mutation (
        $name: String!
        $email: String!
        $phone: String!
        $password: String!
        $plan: String
        $zendesk: Boolean
        $brokerageName: String!
        $chatbotName: String
        $stripeToken: String!
        $useHatchIsaScripts: Boolean
        $realEstate: Boolean
        $mortgage: Boolean
        $software: Boolean
        $solar: Boolean
        $insurance: Boolean
        $travel: Boolean
        $automotive: Boolean
        $support: Boolean
        $other: Boolean
    ) {
        createBrokerage(
            stripeToken: $stripeToken
            plan: $plan
            agent: {
                name: $name
                email: $email
                password: $password
                phone: $phone
                agencyName: $brokerageName
                mlsProviders: []
                mlsNeeded: []
                settings: {
                    defaultChatbotName: $chatbotName
                    chatbotAllowedDomains: {
                        realEstate: $realEstate
                        mortgage: $mortgage
                        software: $software
                        solar: $solar
                        insurance: $insurance
                        travel: $travel
                        automotive: $automotive
                        support: $support
                        other: $other
                    }
                }
            }
            brokerage: {
                name: $brokerageName
                settings: {
                    defaultChatbotName: $chatbotName
                    chatbotAllowedDomains: {
                        realEstate: $realEstate
                        mortgage: $mortgage
                        software: $software
                        solar: $solar
                        insurance: $insurance
                        travel: $travel
                        automotive: $automotive
                        support: $support
                        other: $other
                    }
                }
            }
            useHatchIsaScripts: $useHatchIsaScripts
            isZendesk: $zendesk
        ) {
            brokerageId
            agentId
            accessToken
        }
    }
`;

const MUTATE_INVITE_AGENTS_TO_TEAM = gql`
    mutation ($agents: [AgentInviteType]!, $id: ID!, $accessToken: String) {
        inviteAgentsToTeam(
            agents: $agents
            id: $id
            accessToken: $accessToken
        ) {
            status
        }
    }
`;

const MUTATE_INVITE_AGENTS_TO_BROKERAGE = gql`
    mutation ($agents: [AgentInviteType]!, $id: ID!, $accessToken: String) {
        inviteAgentsToBrokerage(
            agents: $agents
            id: $id
            accessToken: $accessToken
        ) {
            status
        }
    }
`;

const mutations = {
    MUTATE_AGENT_FOR_TEAM,
    MUTATE_AGENT_FOR_BROKERAGE,
    MUTATE_TEAM_FOR_BROKERAGE,
    MUTATE_CREATE_AGENT,
    MUTATE_CREATE_TEAM,
    MUTATE_CREATE_BROKERAGE,
    MUTATE_INVITE_AGENTS_TO_TEAM,
    MUTATE_INVITE_AGENTS_TO_BROKERAGE
};

export default mutations;
