import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';

import ResetPasswordLayout from '../modules/reset/containers/ResetPasswordLayout.js';
import RequestPasswordLayout from '../modules/request/containers/RequestPasswordLayout.js';
import '../styles.scss';
import core from 'modules/core';
const { getUrlVariable, parseToken, showPopover } = core.helpers;

class PasswordLayout extends Component {
    static propTypes = {
        accessToken: PropTypes.string,
        history: PropTypes.object.isRequired,
        updateResetPassword: PropTypes.func.isRequired
    };

    static defaultProps = {
        accessToken: ''
    };

    componentDidMount() {
        this.getResetPasswordData();
    }

    getResetPasswordData() {
        const { updateResetPassword, history } = this.props;

        if (!this.props.accessToken) {
            const accessToken = getUrlVariable('accessToken');

            if (accessToken) {
                const { expires } = parseToken(accessToken);

                let isExpired =
                    new Date().getTime() / 1000 > Math.floor(expires);

                if (!isExpired) {
                    updateResetPassword(accessToken);
                } else {
                    let message =
                        'The reset password link has expired, but no worries you can send another';

                    showPopover(message, 'error');
                    history.push('/reset/request');
                }
            }
        }
    }

    render() {
        return (
            <main className="password-layout" role="main">
                <Switch>
                    <Route
                        exact
                        path="/reset"
                        component={ResetPasswordLayout}
                    />
                    <Route
                        path="/reset/request"
                        component={RequestPasswordLayout}
                    />
                </Switch>
            </main>
        );
    }
}

export default PasswordLayout;
