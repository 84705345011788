import { connect } from 'react-redux';

import Billing from '../components/Billing.jsx';

/** Redux Container **/
const mapStateToProps = state => {
    const { email, billingClientReferenceId } = state.subscribe;

    return {
        email,
        billingClientReferenceId
    };
};
export default connect(mapStateToProps)(Billing);
