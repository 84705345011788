import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdown: false
        };
    }

    static defaultProps = {
        value: '',
        label: '',
        placeholder: 'Select option',
        options: [],
        tabIndex: '0'
    };

    static propTypes = {
        value: PropTypes.string,
        name: PropTypes.string,
        className: PropTypes.string,
        autocomplete: PropTypes.string,
        label: PropTypes.string,
        tabIndex: PropTypes.string,
        placeholder: PropTypes.string,
        options: PropTypes.array,
        onChange: PropTypes.func.isRequired
    };

    toggleDropdown(status) {
        this.setState({ showDropdown: status });
    }

    selectOption(option) {
        const { onChange } = this.props;

        onChange(option);
        this.toggleDropdown(false);
    }

    renderDropdown() {
        const { options, value } = this.props;

        let dropdownOptions = null;
        if (
            options.length === 0 ||
            (options.length === 1 && options[0].value === value)
        ) {
            dropdownOptions = (
                <li className="option null">
                    <span>No options</span>
                </li>
            );
        } else {
            dropdownOptions = options.map((option, index) => {
                return (
                    <li
                        key={index}
                        className="option"
                        onClick={() => this.selectOption(option)}
                    >
                        <span>{option.label}</span>
                    </li>
                );
            });
        }

        return (
            <div className="dropdown-content">
                <ul className="options-list">{dropdownOptions}</ul>
            </div>
        );
    }

    render() {
        const {
            value,
            options,
            label,
            tabIndex,
            placeholder,
            className,
            autocomplete
        } = this.props;
        const { showDropdown } = this.state;

        let currValue = placeholder;
        if (value) {
            let currOption = options.find(option => {
                return option.value === value;
            });

            currValue = currOption ? currOption.label : placeholder;
        }

        return (
            <div className={`select-container ${className ? className : ''}`}>
                {label ? <span className="label">{label}</span> : null}
                <div
                    className="select"
                    tabIndex={tabIndex}
                    autocomplete={autocomplete}
                    onBlur={() => this.toggleDropdown(false)}
                    onFocus={() => this.toggleDropdown(true)}
                >
                    <div
                        className="current-value"
                        onClick={() => this.toggleDropdown(true)}
                    >
                        <p>{currValue}</p>
                        <span className="icon icons8-chevron-down" />
                    </div>
                    {showDropdown ? this.renderDropdown() : null}
                </div>
            </div>
        );
    }
}

export default Select;
