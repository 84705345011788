import actions from './actions.js';
import reducer from './reducer.js';
import mutations from './mutations.js';

const login = {
    actions,
    reducer,
    mutations
};

export default login;
