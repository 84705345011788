import Checkbox from './Checkbox.jsx';
import Footer from './Footer.jsx';
import Header from './Header.jsx';
import Input from './Input.jsx';
import Select from './Select.jsx';
import Spinner from './Spinner.jsx';
import FormNavigation from './FormNavigation.jsx';
import FormAction from './FormAction.jsx';

const components = {
    Checkbox,
    Footer,
    Header,
    Input,
    Select,
    Spinner,
    FormNavigation,
    FormAction
};

export default components;
