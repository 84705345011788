import React from 'react';
import PropTypes from 'prop-types';

import Spinner from './Spinner.jsx';

const FormAction = ({ onSubmit, loading, children }) => {
    const renderValue = () => {
        if (loading) {
            return <Spinner sm />;
        } else {
            return children;
        }
    };

    return (
        <div className="form-action">
            <button className="btn success-btn" onClick={onSubmit}>
                {renderValue()}
            </button>
        </div>
    );
};

FormAction.defaultProps = {
    loading: false,
    children: ''
};

FormAction.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.string,
    onSubmit: PropTypes.func.isRequired
};

export default FormAction;
