import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, Input, Button } from 'blink-ui';

import Radio, { RadioGroup } from './Radio.jsx';
import core from 'modules/core';
const { showPopover } = core.helpers;

class LionDeskLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agencyName: '',
            chatbotName: 'Gabby',
            officeLocation: '',
            version: 'old',
            submitting: false,
            connected: false
        };

        this.changeAgencyName = this.changeAgencyName.bind(this);
        this.changeChatbotName = this.changeChatbotName.bind(this);
        this.changeOfficeLocation = this.changeOfficeLocation.bind(this);
        this.changeVersion = this.changeVersion.bind(this);
        this.validateAgencyName = this.validateAgencyName.bind(this);
        this.validateChatbotName = this.validateChatbotName.bind(this);
        this.validateOfficeLocation = this.validateOfficeLocation.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    static propTypes = {
        integrateLionDesk: PropTypes.func.isRequired
    };

    changeAgencyName(e) {
        this.setState({
            agencyName: e.currentTarget.value
        });
    }

    changeChatbotName(e) {
        this.setState({
            chatbotName: e.currentTarget.value
        });
    }

    changeOfficeLocation(e) {
        this.setState({
            officeLocation: e.currentTarget.value
        });
    }

    changeVersion(e) {
        this.setState({
            version: e
        });
    }

    validateAgencyName(agencyName) {
        if (!agencyName) {
            return 'Your agency name is required';
        }
    }

    validateChatbotName(chatbotName) {
        if (!chatbotName) {
            return 'The chatbot name is required';
        }
    }

    validateOfficeLocation(officeLocation) {
        if (!officeLocation) {
            return 'Your office address is required';
        }
    }

    onSubmit() {
        const { agencyName, chatbotName, officeLocation, version } = this.state;
        const { integrateLionDesk } = this.props;

        if (!agencyName || !chatbotName || !officeLocation || !version) return;

        const handleSubmit = () => {
            integrateLionDesk(agencyName, chatbotName, officeLocation)
                .then(() => {
                    showPopover(
                        'Successfully connected LionDesk AI',
                        'success'
                    );
                    this.setState({
                        submitting: false,
                        connected: true
                    });

                    if (version === 'old') {
                        window.location.href =
                            process.env.REACT_APP_LIONDESK_REDIRECT_URL;
                    } else {
                        window.location.href =
                            process.env.REACT_APP_LIONDESK_REDIRECT_URL_V2;
                    }
                })
                .catch(e => {
                    console.error(e);
                    showPopover('Failed to connect LionDesk AI');
                    this.setState({ submitting: false });
                });
        };

        this.setState({ submitting: true }, handleSubmit);
    }

    renderConnectedNotice() {
        return (
            <div className="connected-notice">
                <h4>Your LionDesk AI has successfully been connected!</h4>
            </div>
        );
    }

    renderContent() {
        const {
            agencyName,
            chatbotName,
            officeLocation,
            version,
            submitting,
            connected
        } = this.state;

        if (connected) return this.renderConnectedNotice();

        return (
            <div className="integration-form">
                <div className="input-wrapper">
                    <Input
                        label="Agency Name"
                        placeholder="Enter your agency name"
                        value={agencyName}
                        onChange={this.changeAgencyName}
                        validator={this.validateAgencyName}
                        onSubmit={this.onSubmit}
                    />
                </div>
                <div className="input-wrapper">
                    <Input
                        label="AI Assistant Name"
                        placeholder="Enter a name for your AI"
                        value={chatbotName}
                        onChange={this.changeChatbotName}
                        validator={this.validateChatbotName}
                        onSubmit={this.onSubmit}
                    />
                </div>
                <div className="input-wrapper">
                    <Input
                        label="Office Address"
                        placeholder="Street Address, City, State"
                        value={officeLocation}
                        onChange={this.changeOfficeLocation}
                        validator={this.validateOfficeLocation}
                        onSubmit={this.onSubmit}
                    />
                </div>
                <div className="input-wrapper">
                    <RadioGroup
                        label="LionDesk Version"
                        name="version"
                        selected={version}
                        onChange={this.changeVersion}
                    >
                        <Radio label="Old" value="old" />
                        <Radio label="New" value="new" />
                    </RadioGroup>
                </div>
                <div className="btn-wrapper">
                    <Button
                        success
                        loading={submitting}
                        onClick={this.onSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="integration-layout">
                <div className="integration-header">
                    <div className="integration-logo liondesk" />
                </div>
                <Paper className="form-wrapper">{this.renderContent()}</Paper>
            </div>
        );
    }
}

export default LionDeskLayout;
