import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withApollo, graphql, compose } from 'react-apollo';

import ProfileDetails from '../components/ProfileDetails.jsx';

import actions from '../actions.js';
import mutations from '../mutations.js';

/** Redux Container **/
const mapStateToProps = state => {
    const {
        name,
        email,
        phone,
        password,
        agencyName,
        realEstate,
        mortgage,
        software,
        solar,
        insurance,
        travel,
        automotive,
        support,
        other,
        addons
    } = state.subscribe;

    return {
        name,
        email,
        phone,
        password,
        agencyName,
        realEstate,
        mortgage,
        software,
        solar,
        insurance,
        travel,
        automotive,
        support,
        other,
        addons
    };
};

const mapDispatchToProps = dispatch => {
    const {
        submitDetails,
        submitAddons,
        cleanSignup,
        setBillingClientReferenceId
    } = actions;

    return bindActionCreators(
        {
            submitDetails,
            submitAddons,
            cleanSignup,
            setBillingClientReferenceId
        },
        dispatch
    );
};

/** GraphQL Container **/
const { MUTATE_SIGNUP_FOR_STRUCTURELY } = mutations;

const withSignupForStructurely = graphql(MUTATE_SIGNUP_FOR_STRUCTURELY, {
    props: ({ mutate, ownProps: { addons } }) => ({
        signupForStructurely({
            name,
            email,
            phone,
            password,
            agencyName,
            realEstate,
            mortgage,
            software,
            solar,
            insurance,
            travel,
            automotive,
            support,
            other
        }) {
            return mutate({
                variables: {
                    useHatchIsaScripts: addons.includes('hatch-scripts'),
                    agent: {
                        name,
                        email,
                        phone: `+1${phone}`,
                        password,
                        agencyName,
                        settings: {
                            chatbotAllowedDomains: {
                                realEstate,
                                mortgage,
                                software,
                                solar,
                                insurance,
                                travel,
                                automotive,
                                support,
                                other
                            }
                        }
                    }
                }
            });
        }
    })
});

export default withApollo(
    compose(
        connect(mapStateToProps, mapDispatchToProps),
        withSignupForStructurely
    )(ProfileDetails)
);
