import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';

import core from 'modules/core';

import '../styles.scss';

const { Header, Footer, Input, FormAction } = core.components;

class RequestPasswordLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            loading: false
        };

        this.changeEmail = this.changeEmail.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    static propTypes = {
        client: PropTypes.object.isRequired,
        sendResetPassword: PropTypes.func.isRequired
    };

    validateEmail(email) {
        if (!email) {
            return 'The email field is required';
        } else {
            let re = /\S+@\S+\.\S+/;

            if (!re.test(email)) {
                return 'The email is invalid, please use a valid email address.';
            }
        }
    }

    changeEmail(e) {
        this.setState({ email: e.currentTarget.value });
    }

    onSubmit() {
        const { showPopover } = core.helpers;
        const { sendResetPassword, client } = this.props;
        const { email } = this.state;

        let hasErrors = this.validateEmail(email);

        if (!hasErrors) {
            const QUERY_IS_EMAIL_TAKEN = gql`
                query ($email: String!) {
                    email(email: $email)
                }
            `;

            this.setState({ loading: true });

            return client
                .query({
                    query: QUERY_IS_EMAIL_TAKEN,
                    variables: { email }
                })
                .then(result => {
                    if (result.data.email) {
                        sendResetPassword(email)
                            .then(result => {
                                this.setState({ loading: false });

                                if (
                                    result &&
                                    result.data &&
                                    result.data.sendResetPassword.status
                                ) {
                                    showPopover(
                                        'Check email for reset password link',
                                        'success'
                                    );
                                } else {
                                    showPopover(
                                        'Failed to send reset password email',
                                        'error'
                                    );
                                }
                            })
                            .catch(error => {
                                this.setState({ loading: false });

                                if (error && error.graphQLErrors) {
                                    error.graphQLErrors.forEach(error => {
                                        showPopover(error.message, 'error');
                                    });
                                } else {
                                    showPopover(
                                        'Failed to send reset password email',
                                        'error'
                                    );
                                }
                            });
                    } else {
                        this.setState({
                            email: '',
                            loading: false
                        });
                        showPopover(
                            `Cannot find account belonging to ${email}`,
                            'error'
                        );
                    }
                })
                .catch(error => {
                    this.setState({ loading: false });
                    if (error && error.graphQLErrors) {
                        error.graphQLErrors.forEach(error => {
                            showPopover(error.message, 'error');
                        });
                    } else {
                        showPopover('Failed to check email', 'error');
                    }
                });
        } else {
            showPopover(hasErrors, 'error');
        }
    }

    render() {
        const { email, loading } = this.state;

        return (
            <section className="request-password-section">
                <Header subtitle="Enter the email belonging to your Structurely account to reset your password" />
                <div className="form-wrapper">
                    <div className="request-password-form form">
                        <Input
                            id="email-input"
                            label="Email"
                            value={email}
                            onChange={this.changeEmail}
                            validator={this.validateEmail}
                            onEnter={this.onSubmit}
                            autoFocus={true}
                        />
                        <FormAction loading={loading} onSubmit={this.onSubmit}>
                            Send Reset Password Email
                        </FormAction>
                    </div>
                    <div className="form-links">
                        <p className="form-link">
                            <Link to="/login">Sign In</Link> or{' '}
                            <Link to="/subscribe">Sign Up</Link>
                        </p>
                    </div>
                </div>
                <Footer />
            </section>
        );
    }
}

export default RequestPasswordLayout;
