import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import core from 'modules/core';

import '../styles.scss';

const { Header, Input, FormNavigation, Checkbox } = core.components;
const { showPopover } = core.helpers;

class ProfileDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: props.name,
            agencyName: props.agencyName,
            email: props.email,
            phone: props.phone,
            password: props.password,
            zendesk: props.zendesk,
            realEstate: props.realEstate,
            mortgage: props.mortgage,
            software: props.software,
            solar: props.solar,
            insurance: props.insurance,
            travel: props.travel,
            automotive: props.automotive,
            financialServices: props.financialServices,
            homeServices: props.homeServices,
            support: props.support,
            other: props.other,
            loading: false,
            chatbotName: 'Aisa'
        };

        this.validateName = this.validateName.bind(this);
        this.validateAgencyName = this.validateAgencyName.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.validateDomains = this.validateDomains.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeAgencyName = this.changeAgencyName.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changePhone = this.changePhone.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.toggleRealEstate = this.toggleRealEstate.bind(this);
        this.toggleMortgage = this.toggleMortgage.bind(this);
        this.toggleSoftware = this.toggleSoftware.bind(this);
        this.toggleSolar = this.toggleSolar.bind(this);
        this.toggleInsurance = this.toggleInsurance.bind(this);
        this.toggleTravel = this.toggleTravel.bind(this);
        this.toggleAutomotive = this.toggleAutomotive.bind(this);
        this.toggleFinancialServices = this.toggleFinancialServices.bind(this);
        this.toggleHomeServices = this.toggleHomeServices.bind(this);
        this.toggleSupport = this.toggleSupport.bind(this);
        this.toggleOther = this.toggleOther.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    static propTypes = {
        name: PropTypes.string,
        agencyName: PropTypes.string,
        email: PropTypes.string,
        inviteType: PropTypes.string,
        inviteSource: PropTypes.string,
        invited: PropTypes.bool,
        discounted: PropTypes.bool,
        password: PropTypes.string,
        phone: PropTypes.string,
        paymentPlan: PropTypes.string,
        realEstate: PropTypes.bool,
        mortgage: PropTypes.bool,
        software: PropTypes.bool,
        solar: PropTypes.bool,
        insurance: PropTypes.bool,
        travel: PropTypes.bool,
        automotive: PropTypes.bool,
        financialServices: PropTypes.bool,
        homeServices: PropTypes.bool,
        support: PropTypes.bool,
        other: PropTypes.bool,
        zendesk: PropTypes.bool,
        client: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        submitDetails: PropTypes.func.isRequired,
        submitAddons: PropTypes.func.isRequired,
        createAgentForTeam: PropTypes.func.isRequired,
        createAgentForBrokerage: PropTypes.func.isRequired,
        createTeamForBrokerage: PropTypes.func.isRequired,
        cleanSignup: PropTypes.func.isRequired,
        addons: PropTypes.array
    };

    static defaultProps = {
        name: '',
        agencyName: '',
        email: '',
        password: '',
        phone: '',
        paymentPlan: '',
        inviteType: '',
        realEstate: false,
        mortgage: false,
        software: false,
        solar: false,
        insurance: false,
        travel: false,
        automotive: false,
        homeServices: false,
        financialServices: false,
        support: false,
        other: false,
        discounted: false,
        zendesk: false,
        addons: []
    };

    componentWillReceiveProps(nextProps) {
        const { email } = nextProps;

        this.setState({ email });
    }

    validateName(name) {
        if (!name) {
            return 'The name field is required';
        } else if (name.trim().split(' ').length < 2) {
            return 'Both a first and last name are required';
        }
    }

    validateAgencyName(name) {
        if (!name) {
            return 'The company name field is required';
        }
    }

    validateEmail(email) {
        if (!email) {
            return 'The email field is required';
        } else {
            let re = /\S+@\S+\.\S+/;

            if (!re.test(email)) {
                return 'The email is invalid, please use a valid email address.';
            }
        }
    }

    validatePhone(phone) {
        if (!phone) {
            return 'The phone field is required';
        } else if (phone.replace(/\D/g, '').length !== 10) {
            return 'The phone field must contain a 10 digit code representing a phone number';
        }
    }

    validatePassword(password) {
        const minPasswordLength = 8;

        if (!password) {
            return 'The password field is required';
        } else if (password.length < minPasswordLength) {
            return `Your password must be at least ${minPasswordLength} characters long`;
        }
    }

    validateDomains(domains) {
        if (!domains) return 'Must select at least one domain';
    }

    changeName(e) {
        this.setState({ name: e.currentTarget.value });
    }

    changeAgencyName(e) {
        this.setState({ agencyName: e.currentTarget.value });
    }

    changeEmail(e) {
        this.setState({ email: e.currentTarget.value });
    }

    changePhone(e) {
        const { phone } = this.state;
        const { helpers } = core;

        let value = e.currentTarget.value;
        let phoneNumber = value.replace(/\D/g, '');

        if (value) {
            if (helpers.getFormattedPhoneNumber(phone).length > value.length) {
                phoneNumber = phone.substring(0, phone.length - 1);
            }

            if (phoneNumber.length > 10) {
                phoneNumber = phoneNumber.substring(0, 10);
            }

            this.setState({ phone: phoneNumber });
        }
    }

    changePassword(e) {
        this.setState({ password: e.currentTarget.value });
    }

    toggleRealEstate() {
        this.setState(prevState => ({ realEstate: !prevState.realEstate }));
    }

    toggleMortgage() {
        this.setState(prevState => ({ mortgage: !prevState.mortgage }));
    }

    toggleSoftware() {
        this.setState(prevState => ({ software: !prevState.software }));
    }

    toggleSolar() {
        this.setState(prevState => ({ solar: !prevState.solar }));
    }

    toggleInsurance() {
        this.setState(prevState => ({ insurance: !prevState.insurance }));
    }

    toggleTravel() {
        this.setState(prevState => ({ travel: !prevState.travel }));
    }

    toggleAutomotive() {
        this.setState(prevState => ({ automotive: !prevState.automotive }));
    }

    toggleFinancialServices() {
        this.setState(prevState => ({
            financialServices: !prevState.financialServices
        }));
    }

    toggleHomeServices() {
        this.setState(prevState => ({ homeServices: !prevState.homeServices }));
    }

    toggleSupport() {
        this.setState(prevState => ({ support: !prevState.support }));
    }

    toggleOther() {
        this.setState(prevState => ({ other: !prevState.other }));
    }

    toggleAddon(addon) {
        const { addons, submitAddons } = this.props;

        let newAddons = addons.slice(0);

        if (newAddons.includes(addon)) {
            newAddons = newAddons.filter(
                existingAddon => existingAddon !== addon
            );
        } else {
            newAddons.push(addon);
        }

        submitAddons(newAddons);
    }

    validateForm() {
        const { inviteType } = this.props;
        const {
            name,
            agencyName,
            email,
            phone,
            password,
            realEstate,
            mortgage,
            software,
            solar,
            insurance,
            travel,
            automotive,
            financialServices,
            homeServices,
            support,
            other
        } = this.state;

        let hasErrors =
            this.validateName(name) ||
            this.validateEmail(email) ||
            this.validatePhone(phone) ||
            this.validatePassword(password) ||
            this.validateDomains(
                realEstate ||
                    mortgage ||
                    software ||
                    solar ||
                    insurance ||
                    travel ||
                    automotive ||
                    support ||
                    financialServices ||
                    homeServices ||
                    other
            );

        if (inviteType !== 'agent') {
            hasErrors = hasErrors || this.validateAgencyName(agencyName);
        }

        return hasErrors;
    }

    onSuccess() {
        const { cleanSignup, history } = this.props;

        cleanSignup();
        showPopover('Successfully created account', 'success');
        history.push('/login');
    }

    createAgentForTeam(chatbotName) {
        const { createAgentForTeam } = this.props;

        if (!createAgentForTeam) return;

        const handleCreateAgent = () =>
            createAgentForTeam(chatbotName)
                .then(result => {
                    const { data } = result;

                    if (
                        data &&
                        data.createAgentForTeam &&
                        data.createAgentForTeam.agentId
                    ) {
                        this.onSuccess();
                    } else {
                        showPopover('Failed to create account', 'error');
                    }

                    this.setState({ submitting: false });
                })
                .catch(error => {
                    this.setState({ submitting: false });
                    if (error && error.graphQLErrors) {
                        error.graphQLErrors.forEach(error => {
                            showPopover(error.message, 'error');
                        });
                    } else {
                        showPopover('Failed to create account', 'error');
                    }
                });

        this.setState({ submitting: true }, handleCreateAgent);
    }

    createAgentForBrokerage(chatbotName) {
        const { createAgentForBrokerage } = this.props;

        if (!createAgentForBrokerage) return;

        const handleCreateAgent = () =>
            createAgentForBrokerage(chatbotName)
                .then(result => {
                    const { data } = result;

                    if (
                        data &&
                        data.createAgentForBrokerage &&
                        data.createAgentForBrokerage.agentId
                    ) {
                        this.onSuccess();
                    } else {
                        showPopover('Failed to create account', 'error');
                    }

                    this.setState({ submitting: false });
                })
                .catch(error => {
                    this.setState({ submitting: false });
                    if (error && error.graphQLErrors) {
                        error.graphQLErrors.forEach(error => {
                            showPopover(error.message, 'error');
                        });
                    } else {
                        showPopover('Failed to create account', 'error');
                    }
                });

        this.setState({ submitting: true }, handleCreateAgent);
    }

    createTeamForBrokerage(chatbotName) {
        const { createTeamForBrokerage } = this.props;

        if (!createTeamForBrokerage) return;

        const handleCreateAgent = () =>
            createTeamForBrokerage(chatbotName)
                .then(result => {
                    const { data } = result;

                    if (
                        data &&
                        data.createTeamForBrokerage &&
                        data.createTeamForBrokerage.agentId &&
                        data.createTeamForBrokerage.teamId
                    ) {
                        this.onSuccess();
                    } else {
                        showPopover('Failed to create account', 'error');
                    }

                    this.setState({ submitting: false });
                })
                .catch(error => {
                    this.setState({ submitting: false });
                    if (error && error.graphQLErrors) {
                        error.graphQLErrors.forEach(error => {
                            showPopover(error.message, 'error');
                        });
                    } else {
                        showPopover('Failed to create account', 'error');
                    }
                });

        this.setState({ submitting: true }, handleCreateAgent);
    }

    onSubmit() {
        const { showPopover, capitalize } = core.helpers;
        const { submitDetails, client, invited, inviteSource, inviteType } =
            this.props;
        const {
            name,
            agencyName,
            email,
            phone,
            password,
            chatbotName,
            zendesk,
            realEstate,
            mortgage,
            software,
            solar,
            insurance,
            travel,
            automotive,
            financialServices,
            homeServices,
            support,
            other
        } = this.state;

        if (!invited) {
            showPopover('Unable to signup without an invite', 'error');
            return;
        }

        let hasErrors = this.validateForm();

        if (!hasErrors) {
            this.setState({ loading: true });

            const QUERY_IS_EMAIL_TAKEN = gql`
                query ($email: String!) {
                    email(email: $email)
                }
            `;

            return client
                .query({
                    query: QUERY_IS_EMAIL_TAKEN,
                    variables: { email }
                })
                .then(result => {
                    if (!result.data.email) {
                        let fullName = name.split(' ');
                        fullName = `${capitalize(fullName[0])} ${capitalize(
                            fullName[1]
                        )}`;

                        submitDetails(
                            fullName,
                            email,
                            phone,
                            password,
                            agencyName,
                            zendesk,
                            realEstate,
                            mortgage,
                            software,
                            solar,
                            insurance,
                            travel,
                            automotive,
                            financialServices,
                            homeServices,
                            support,
                            other
                        );

                        this.setState({ loading: false });

                        if (inviteSource === 'team') {
                            this.createAgentForTeam(chatbotName);
                        } else if (inviteSource === 'brokerage') {
                            if (inviteType === 'agent') {
                                this.createAgentForBrokerage(chatbotName);
                            } else if (inviteType === 'team') {
                                this.createTeamForBrokerage(chatbotName);
                            }
                        }
                    } else {
                        this.setState({
                            email: '',
                            loading: false
                        });
                        showPopover('Email already taken', 'error');
                    }
                })
                .catch(error => {
                    this.setState({ loading: false });
                    if (error && error.graphQLErrors) {
                        error.graphQLErrors.forEach(error => {
                            showPopover(error.message, 'error');
                        });
                    } else {
                        showPopover('Failed to check email', 'error');
                    }
                });
        } else {
            showPopover(hasErrors, 'error');
        }

        return hasErrors;
    }

    renderAgencyName() {
        const { agencyName } = this.state;
        const { inviteType } = this.props;

        if (inviteType === 'agent') return null;

        return (
            <Input
                id="agency-input"
                label="Company Name"
                value={agencyName}
                autocomplete="organization"
                onChange={this.changeAgencyName}
                onEnter={this.onSubmit}
                validator={this.validateAgencyName}
            />
        );
    }

    renderAddons() {
        const { realEstate } = this.state;

        if (!realEstate) {
            return <div></div>;
        }

        return <div></div>;
    }

    render() {
        const {
            name,
            email,
            phone,
            password,
            realEstate,
            mortgage,
            software,
            solar,
            insurance,
            travel,
            automotive,
            financialServices,
            homeServices,
            support,
            other
        } = this.state;
        const { getFormattedPhoneNumber } = core.helpers;

        let nextText = 'Complete Sign up';
        return (
            <section className="details-section">
                <Header
                    title="Hi! I’m Your AI Assistant"
                    subtitle="I just need a few details to help you"
                />
                <div className="form-wrapper">
                    <div className="details-form form">
                        <Input
                            id="name-input"
                            label="Full Name"
                            value={name}
                            autocomplete="name"
                            onChange={this.changeName}
                            onEnter={this.onSubmit}
                            validator={this.validateName}
                            autoFocus={true}
                        />
                        {this.renderAgencyName()}
                        <Input
                            id="phone-input"
                            label="Phone Number"
                            type="phone"
                            autocomplete="tel"
                            value={getFormattedPhoneNumber(phone)}
                            onChange={this.changePhone}
                            onEnter={this.onSubmit}
                            validator={this.validatePhone}
                        />
                        <Input
                            id="email-input"
                            label="Work Email"
                            type="email"
                            value={email}
                            autocomplete="email"
                            onChange={this.changeEmail}
                            onEnter={this.onSubmit}
                            validator={this.validateEmail}
                            disabled={true}
                        />
                        <Input
                            id="password-input"
                            label="Password"
                            type="password"
                            value={password}
                            autocomplete="new-password"
                            onChange={this.changePassword}
                            onEnter={this.onSubmit}
                            validator={this.validatePassword}
                        />
                        <div className="checkbox-group">
                            <h4 className="checkbox-group-title">Industry</h4>
                            <div className="domains_group">
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Real Estate"
                                        checked={realEstate}
                                        onChange={this.toggleRealEstate}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Mortgage"
                                        checked={mortgage}
                                        onChange={this.toggleMortgage}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Software"
                                        checked={software}
                                        onChange={this.toggleSoftware}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Solar"
                                        checked={solar}
                                        onChange={this.toggleSolar}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Insurance"
                                        checked={insurance}
                                        onChange={this.toggleInsurance}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Travel"
                                        checked={travel}
                                        onChange={this.toggleTravel}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Automotive"
                                        checked={automotive}
                                        onChange={this.toggleAutomotive}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Financial Services"
                                        checked={financialServices}
                                        onChange={this.toggleFinancialServices}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Home Services"
                                        checked={homeServices}
                                        onChange={this.toggleHomeServices}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Support"
                                        checked={support}
                                        onChange={this.toggleSupport}
                                    />
                                </div>
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        label="Other"
                                        checked={other}
                                        onChange={this.toggleOther}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.renderAddons()}
                    </div>
                    <FormNavigation
                        onSubmit={() => this.onSubmit()}
                        btnText={nextText}
                    />
                </div>
            </section>
        );
    }
}

export default ProfileDetails;
