import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { graphql, compose } from 'react-apollo';

import ResetPasswordLayout from '../components/ResetPasswordLayout.jsx';

import mutations from '../../../mutations.js';

/** Redux Container **/
const mapStateToProps = state => {
    const { accessToken } = state.reset.resetPassword;

    return {
        accessToken
    };
};

const mapDispatchToProps = () => {
    return {};
};

/** GraphQL Container **/
const { RESET_PASSWORD } = mutations;

const withResetPassword = graphql(RESET_PASSWORD, {
    options: ({ accessToken }) => ({ variables: { accessToken } }),
    props: ({ mutate }) => ({
        resetPassword(newPassword) {
            return mutate({
                variables: { newPassword }
            });
        }
    })
});

export default withRouter(
    compose(
        connect(
            mapStateToProps,
            mapDispatchToProps
        ),
        withResetPassword
    )(ResetPasswordLayout)
);
