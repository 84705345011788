import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// TODO: Remove theme styles
const value = ({ checked }) => {
    if (checked) {
        return css`
            & > span.checkbox-wrapper > span.checkbox-checkmark {
                border-color: transparent;
                background-color: #2a354f;
                box-shadow: 0 0 6px -3px #2a354f;
            }

            & > span.checkbox-label {
                font-weight: 500;
            }
        `;
    }
};

const StyledCheckbox = styled.label`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    height: 20px;
    padding-left: 8px;
    cursor: pointer;
    align-self: flex-start;

    &:last-child {
        margin-bottom: 0;
    }

    & > span.checkbox-wrapper {
        & > input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            box-sizing: border-box;
            padding: 0;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
        }

        & > span.checkbox-checkmark {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            border: 1px solid #2a354f;
            background-color: #fff;
            box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.08);

            &:after {
                content: '';
                position: absolute;
                top: 7.25px;
                left: 3px;
                display: table;
                width: 5px;
                height: 10px;
                border: 2px solid #fff;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                box-sizing: border-box;
            }
        }
    }

    & > span.checkbox-label {
        margin-left: 8px;
        font-size: 14px;
    }

    ${value};
`;

function Checkbox(props) {
    const { name, label, value, checked, readOnly, ...other } = props;

    return (
        <StyledCheckbox checked={checked} readOnly={readOnly}>
            <span className="checkbox-wrapper">
                <input
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={checked}
                    {...other}
                />
                <span className="checkbox-checkmark" />
            </span>
            <span className="checkbox-label">{label}</span>
        </StyledCheckbox>
    );
}

Checkbox.propTypes = {
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    name: PropTypes.string,
    checked: PropTypes.bool,
    label: PropTypes.string
};

Checkbox.defaultProps = {
    value: '',
    readOnly: false,
    checked: false,
    name: 'checkbox-group',
    label: ''
};

export default Checkbox;
