import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withApollo, graphql, compose } from 'react-apollo';

import ProfileDetails from '../components/ProfileDetails.jsx';

import actions from '../../../actions.js';
import mutations from '../../../mutations.js';

/** Redux Container **/
const mapStateToProps = state => {
    const {
        name,
        email,
        phone,
        password,
        invitation,
        agencyName,
        paymentPlan,
        realEstate,
        mortgage,
        software,
        zendesk,
        solar,
        insurance,
        travel,
        automotive,
        homeServices,
        financialServices,
        support,
        other,
        addons
    } = state.signup;

    const accessToken = invitation ? invitation.accessToken : '';

    let inviteSource = '';
    if (invitation) {
        if (invitation.teamId) {
            inviteSource = 'team';
        } else if (invitation.brokerageId) {
            inviteSource = 'brokerage';
        }
    }

    return {
        inviteType: invitation ? invitation.inviteType : '',
        invited: invitation ? !!invitation.accessToken : false,
        teamId: invitation ? invitation.teamId : '',
        brokerageId: invitation ? invitation.brokerageId : '',
        inviteSource,
        accessToken,
        name,
        email,
        phone,
        password,
        agencyName,
        paymentPlan,
        zendesk,
        realEstate,
        mortgage,
        software,
        solar,
        insurance,
        travel,
        automotive,
        homeServices,
        financialServices,
        support,
        other,
        addons
    };
};

const mapDispatchToProps = dispatch => {
    const { submitDetails, submitAddons, cleanSignup } = actions;

    return bindActionCreators(
        {
            submitDetails,
            submitAddons,
            cleanSignup
        },
        dispatch
    );
};

/** GraphQL Container **/
const {
    MUTATE_AGENT_FOR_TEAM,
    MUTATE_AGENT_FOR_BROKERAGE,
    MUTATE_TEAM_FOR_BROKERAGE
} = mutations;

const withCreateAgentForTeam = graphql(MUTATE_AGENT_FOR_TEAM, {
    props: ({
        mutate,
        ownProps: {
            email,
            password,
            name,
            phone,
            accessToken,
            teamId,
            agencyName,
            realEstate,
            mortgage
        }
    }) => ({
        createAgentForTeam(chatbotName) {
            return mutate({
                variables: {
                    chatbotName,
                    email,
                    password,
                    name,
                    phone: `+1${phone}`,
                    accessToken,
                    teamId,
                    agencyName,
                    realEstate,
                    mortgage
                }
            });
        }
    })
});

const withCreateAgentForBrokerage = graphql(MUTATE_AGENT_FOR_BROKERAGE, {
    props: ({
        mutate,
        ownProps: {
            email,
            password,
            name,
            phone,
            accessToken,
            brokerageId,
            agencyName,
            realEstate,
            mortgage
        }
    }) => ({
        createAgentForBrokerage(chatbotName) {
            return mutate({
                variables: {
                    chatbotName,
                    email,
                    password,
                    name,
                    phone: `+1${phone}`,
                    accessToken,
                    brokerageId,
                    agencyName,
                    realEstate,
                    mortgage
                }
            });
        }
    })
});

const withCreateTeamForBrokerage = graphql(MUTATE_TEAM_FOR_BROKERAGE, {
    props: ({
        mutate,
        ownProps: {
            email,
            password,
            name,
            agencyName,
            phone,
            accessToken,
            brokerageId,
            realEstate,
            mortgage
        }
    }) => ({
        createTeamForBrokerage(chatbotName) {
            return mutate({
                variables: {
                    chatbotName,
                    email,
                    password,
                    name,
                    teamName: agencyName,
                    phone: `+1${phone}`,
                    accessToken,
                    brokerageId,
                    realEstate,
                    mortgage
                }
            });
        }
    })
});

export default withApollo(
    compose(
        connect(mapStateToProps, mapDispatchToProps),
        withCreateAgentForTeam,
        withCreateAgentForBrokerage,
        withCreateTeamForBrokerage
    )(ProfileDetails)
);
