const initialState = {
    email: '',
    notice: ''
};

const login = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_LOGIN_EMAIL':
            return {
                ...state,
                email: action.email
            };
        case 'UPDATE_LOGIN_NOTICE':
            return {
                ...state,
                notice: action.notice
            };
        default:
            return state;
    }
};

const reducer = {
    login
};

export default reducer;
