import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import core from 'modules/core';

import '../styles.scss';

const { Header, Footer, Input, FormAction } = core.components;

class ResetPasswordLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            loading: false
        };

        this.changePassword = this.changePassword.bind(this);
        this.changeConfirmPassword = this.changeConfirmPassword.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    static propTypes = {
        history: PropTypes.object.isRequired,
        resetPassword: PropTypes.func.isRequired
    };

    validatePassword(password) {
        const minPasswordLength = 8;

        if (!password) {
            return 'The password field is required';
        } else if (password.length < minPasswordLength) {
            return `Your password must be at least ${minPasswordLength} characters long`;
        }
    }

    validateConfirmPassword(confirmPassword) {
        const { password } = this.state;

        if (!password) {
            return 'The confirm password field is required';
        } else if (confirmPassword !== password) {
            return 'modules/passwords do not match';
        }
    }

    changePassword(e) {
        this.setState({ password: e.currentTarget.value });
    }

    changeConfirmPassword(e) {
        this.setState({ confirmPassword: e.currentTarget.value });
    }

    onSubmit() {
        const { showPopover } = core.helpers;
        const { resetPassword, history } = this.props;
        const { password, confirmPassword } = this.state;

        let hasErrors =
            this.validatePassword(password) ||
            this.validateConfirmPassword(confirmPassword);

        if (!hasErrors) {
            this.setState({ loading: true });

            resetPassword(password)
                .then(result => {
                    this.setState({ loading: false });

                    if (result.data && result.data.resetPassword.status) {
                        showPopover('Successfully reset password', 'success');

                        history.push('/login');
                    } else {
                        showPopover('Failed to reset password', 'error');
                    }
                })
                .catch(error => {
                    this.setState({ loading: false });
                    if (error && error.graphQLErrors) {
                        error.graphQLErrors.forEach(error => {
                            showPopover(error.message, 'error');
                        });
                    } else {
                        showPopover('Failed to create account', 'error');
                    }
                });
        } else {
            showPopover(hasErrors, 'error');
        }
    }

    render() {
        const { password, confirmPassword, loading } = this.state;

        return (
            <section className="reset-password-section">
                <Header subtitle="Enter and confirm your new password " />
                <div className="form-wrapper">
                    <div className="request-password-form form">
                        <Input
                            id="password-input"
                            label="New Password"
                            type="password"
                            value={password}
                            onChange={this.changePassword}
                            onEnter={this.onSubmit}
                            validator={this.validatePassword}
                            autoFocus={true}
                        />
                        <Input
                            id="confirm-password-input"
                            label="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={this.changeConfirmPassword}
                            onEnter={this.onSubmit}
                            validator={this.validateConfirmPassword}
                        />
                        <FormAction loading={loading} onSubmit={this.onSubmit}>
                            Reset Password
                        </FormAction>
                    </div>
                    <div className="form-links">
                        <p className="form-link">
                            <Link to="/login">Sign In</Link> or{' '}
                            <Link to="/subscribe">Sign Up</Link>
                        </p>
                    </div>
                </div>
                <Footer />
            </section>
        );
    }
}

export default ResetPasswordLayout;
