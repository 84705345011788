import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { Provider as ReduxProvider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import { HashRouter } from 'react-router-dom';

import 'modules/core/styles.scss';
import MainLayout from '../containers/MainLayout.js';

class App extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        client: PropTypes.object.isRequired
    };

    render() {
        const { client, store } = this.props;

        return (
            <ApolloProvider client={client}>
                <ReduxProvider store={store}>
                    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                        <HashRouter>
                            <MainLayout />
                        </HashRouter>
                    </StripeProvider>
                </ReduxProvider>
            </ApolloProvider>
        );
    }
}

export default App;
