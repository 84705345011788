import { connect } from 'react-redux';
import { withApollo, graphql, compose } from 'react-apollo';

import LoginLayout from '../components/LoginLayout.jsx';

import mutations from '../mutations.js';

/** Redux Container **/
const mapStateToProps = state => {
    const { redirectUrl } = state.core;
    const { email, notice } = state.login;

    return {
        redirectUrl,
        email,
        notice
    };
};

const mapDispatchToProps = () => {
    return {};
};

/** GraphQL Container **/
const { MUTATE_VALIDATE_AGENT } = mutations;

const withCreateAgent = graphql(MUTATE_VALIDATE_AGENT, {
    props: ({ mutate }) => ({
        validateUser(accessToken) {
            return mutate({
                variables: { accessToken }
            });
        }
    })
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withApollo,
    withCreateAgent
)(LoginLayout);
