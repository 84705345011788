import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import { graphql, compose } from 'react-apollo';

import { MUTATE_VERIFY_AGENT_FOR_RESELLER } from '../mutations';
import { ResellerSignupComponent } from '../components/ResellerSignupComponent';

import core from 'modules/core';
const { getUrlVariable, parseToken, showPopover } = core.helpers;

const withVerifyReseller = graphql(MUTATE_VERIFY_AGENT_FOR_RESELLER, {
    options: ({
        name,
        email,
        password,
        phone,
        accessToken,
        agencyName,
        chatbotName,
        automotive,
        financialServices,
        homeService,
        insurance,
        mortgage,
        realEstate,
        solar
    }) => ({
        variables: {
            name,
            email,
            password,
            phone,
            accessToken,
            agencyName,
            chatbotName,
            automotive,
            financialServices,
            homeService,
            insurance,
            mortgage,
            realEstate,
            solar
        }
    }),
    props: ({ mutate }) => ({
        verifyReseller(props) {
            return mutate({
                variables: props
            });
        }
    })
});

const ResellerSignupContainer = ({ history, verifyReseller }) => {
    const accessToken = getUrlVariable('accessToken');
    if (!accessToken) {
        console.error('There was no access token, redirecting to login');
        showPopover('There was no access token to verify', 'error');
        setTimeout(() => history.push('/login'), 1000);
        return <div>There was no access token, redirecting to login</div>;
    }

    const { expires, email, name, agencyName } = parseToken(accessToken);
    if (new Date().getTime() / 1000 > Math.floor(expires)) {
        console.error('The access token has expired');
        let message =
            'The access token has expired. Have your Team Leader or Broker re-invite ' +
            'you to continue setting up your Structurely account.';

        showPopover(message, 'error');
        setTimeout(() => history.push('/login'), 1000);

        return <div>The access token has expired</div>;
    }

    const onVerifyReseller = props => {
        verifyReseller({ accessToken, ...props }).then(() => {
            showPopover('Successfully registered account', 'success');
            setTimeout(() => history.push('/login'), 500);
        });
    };

    return (
        <ResellerSignupComponent
            name={name}
            email={email}
            agencyName={agencyName}
            onVerifyReseller={onVerifyReseller}
        />
    );
};

ResellerSignupContainer.propTypes = {
    history: PropTypes.object.isRequired,
    verifyReseller: PropTypes.func.isRequired
};

export default withRouter(compose(withVerifyReseller)(ResellerSignupContainer));
