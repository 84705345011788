import React, { Component } from 'react';
import { Switch, Route } from 'react-router';

import '../styles.scss';
import LionDeskLayout from '../modules/liondesk/containers/LionDeskLayout.js';

class IntegrationsLayout extends Component {
    componentDidMount() {
        if (window.Intercom) window.Intercom('shutdown');
    }

    render() {
        return (
            <Switch>
                <Route
                    path="/integrations/liondesk"
                    component={LionDeskLayout}
                />
            </Switch>
        );
    }
}

export default IntegrationsLayout;
