import gql from 'graphql-tag';

const MUTATE_VALIDATE_AGENT = gql`
    mutation($accessToken: String!) {
        validateAgent(accessToken: $accessToken) {
            status
        }
    }
`;

const mutations = {
    MUTATE_VALIDATE_AGENT
};

export default mutations;
