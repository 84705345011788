import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import ProfileDetails from '../modules/details/containers/ProfileDetails.js';
import PaymentPlan from '../modules/paymentPlan/containers/PaymentPlan.js';

import '../styles.scss';
import core from 'modules/core';
const { getUrlVariable, parseToken, showPopover } = core.helpers;

class SignupLayout extends Component {
    static propTypes = {
        invitation: PropTypes.object,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        updateInviteData: PropTypes.func.isRequired,
        submitPaymentPlan: PropTypes.func.isRequired
    };

    static defaultProps = {
        invitation: {
            accessToken: '',
            expires: '',
            teamId: '',
            brokerageId: ''
        }
    };

    componentDidMount() {
        this.getInviteData();
    }

    getInviteData() {
        const { history, updateInviteData, invitation } = this.props;

        // If no access token and no invitation -> subscribe
        // If access token and no invitation -> details
        if (invitation?.accessToken) {
            history.push('/signup/details');
        } else {
            const accessToken = getUrlVariable('accessToken');
            if (!accessToken) {
                history.push('/subscribe');
            } else {
                const { expires, email, inviteType, brokerageID, teamID } =
                    parseToken(accessToken);

                let isExpired =
                    new Date().getTime() / 1000 > Math.floor(expires);

                if (!isExpired) {
                    updateInviteData({
                        accessToken,
                        expires,
                        email,
                        inviteType,
                        teamId: teamID,
                        brokerageId: brokerageID
                    });
                    history.push('/signup/details');
                } else {
                    let message =
                        'The access token has expired. Have your Team Leader or Broker re-invite ' +
                        'you to continue setting up your Structurely account.';

                    showPopover(message, 'error');
                    history.push('/login');
                }
            }
        }
    }

    render() {
        return (
            <main className="signup-layout" role="main">
                <Switch>
                    <Route exact path="/signup" component={PaymentPlan} />
                    <Route path="/signup/details" component={ProfileDetails} />
                </Switch>
            </main>
        );
    }
}

export default SignupLayout;
