import { createStore, combineReducers } from 'redux';

import core from 'modules/core';
import signup from 'modules/signup';
import login from 'modules/login';
import password from 'modules/password';
import subscribe from 'modules/subscribe';

const reducer = combineReducers({
    ...core.reducer,
    ...signup.reducer,
    ...subscribe.reducer,
    ...login.reducer,
    ...password.reducer
});

export default createStore(reducer, {});
