import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PlanItem extends Component {
    static defaultProps = {
        type: '',
        title: '',
        subtitle: '',
        leads: 0,
        price: '',
        features: [],
        isSelected: false
    };

    static propTypes = {
        type: PropTypes.string,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        leads: PropTypes.number,
        price: PropTypes.string,
        isSelected: PropTypes.bool,
        features: PropTypes.array,
        onClick: PropTypes.func.isRequired
    };

    renderFeatures() {
        const { features } = this.props;

        return features.map((feature, index) => {
            return (
                <li key={index} className="feature-item">
                    <p>{feature}</p>
                </li>
            );
        });
    }

    renderSubtitle() {
        const { leads } = this.props;

        if (!leads) return null;

        return (
            <div className="plan-subtitle">
                <span>{`${leads} contacts/mo`}</span>
            </div>
        );
    }

    render() {
        const { type, title, price, onClick, isSelected } = this.props;

        let className = `plan-item ${type} ${isSelected ? 'active' : ''}`;

        return (
            <li className={className} onClick={onClick}>
                <div className="plan-icon-wrapper">
                    <span className={`icons8 ${type}-icon`} />
                </div>
                <div className="plan-header">
                    <h3 className="plan-title">{title}</h3>
                    {this.renderSubtitle()}
                </div>
                <div className="plan-action">
                    <hr />
                    <div className="btn-wrapper">
                        <button className="select-btn">{price}</button>
                    </div>
                </div>
                <ul className="plan-features">{this.renderFeatures()}</ul>
            </li>
        );
    }
}

export default PlanItem;
