import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledRadioGroup = styled.div`
    display: flex;
    flex-direction: column;

    & > span {
        font-size: 16px;
        margin-bottom: 8px;
    }
`;

export const RadioGroup = ({
    label,
    name,
    required,
    children,
    selected,
    readOnly,
    onChange,
    onSubmit,
    ...other
}) => {
    const renderLabel = () => {
        if (readOnly) {
            return <span>{label}</span>;
        } else {
            return (
                <span>
                    {label}
                    <b>{required ? ' *' : ''}</b>
                </span>
            );
        }
    };
    return (
        <StyledRadioGroup {...other}>
            {renderLabel()}
            {React.Children.map(children, child =>
                React.cloneElement(child, {
                    name,
                    selectedOption: selected,
                    onChange: readOnly ? () => null : onChange,
                    onSubmit: readOnly ? null : onSubmit,
                    readOnly
                })
            )}
        </StyledRadioGroup>
    );
};

RadioGroup.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    selected: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]).isRequired
};

RadioGroup.defaultProps = {
    required: false,
    readOnly: false,
    selected: '',
    onSubmit: null
};

const value = ({ checked }) => {
    if (checked) {
        return css`
            & > span.radio-checkmark {
                background-color: #005ea1;
                box-shadow: 0 0 6px -3px #005ea1;

                &:after {
                    top: 4px;
                    left: 4px;
                    width: 8px;
                    height: 8px;
                }
            }

            & > span.radio-label {
                font-weight: 500;
                color: #3c4a54;
            }
        `;
    } else {
        return css`
            & > span.radio-checkmark {
                background-color: #9e9e9e;

                &:after {
                    top: 1px;
                    left: 1px;
                    width: 14px;
                    height: 14px;
                }
            }
        `;
    }
};

const StyledRadio = styled.label`
    display: flex;
    margin-bottom: 16px;
    position: relative;
    min-height: 20px;
    padding-left: 24px;
    cursor: pointer;
    align-self: flex-start;
    width: 100%;
    outline: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &:last-child {
        margin-bottom: 0;
    }

    & > input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    & > span.radio-checkmark {
        position: absolute;
        top: 2px;
        left: 6px;
        height: 16px;
        width: 16px;
        border-radius: 50%;

        &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            background-color: white;
        }
    }

    & > span.radio-label {
        margin-left: 8px;
        line-height: 1.25;
        font-size: 16px;
    }

    & > div.radio-content {
        margin-left: 8px;
        width: calc(100% - 32px);
    }

    &:hover {
        & > span.radio-checkmark {
            background-color: #005ea1;
        }
    }

    ${value};
`;

const Radio = ({
    name,
    label,
    value,
    selectedOption,
    readOnly,
    children,
    tabIndex,
    onChange,
    onSubmit,
    autoFocus,
    ...other
}) => {
    let radioRef = useRef();

    useEffect(() => {
        if (autoFocus && radioRef && radioRef.current) {
            radioRef.current.focus();
        }
        // eslint-disable-next-line
    }, []);

    let checked = selectedOption === value;

    const handleKeys = e => {
        switch (e.keyCode) {
            case 13:
                if (onSubmit) {
                    onSubmit(value);
                }
                break;
            case 38:
                if (
                    radioRef &&
                    radioRef.current &&
                    radioRef.current.previousSibling
                ) {
                    if (radioRef.current.previousSibling.tabIndex === 0) {
                        radioRef.current.previousSibling.focus();
                    }
                }
                break;
            case 40:
                if (
                    radioRef &&
                    radioRef.current &&
                    radioRef.current.nextSibling
                ) {
                    if (radioRef.current.nextSibling.tabIndex === 0) {
                        radioRef.current.nextSibling.focus();
                    }
                }
                break;
            default:
                break;
        }
    };

    const renderContent = () => {
        return <div className="radio-content">{children}</div>;
    };

    return (
        <StyledRadio
            checked={checked}
            readOnly={readOnly}
            tabIndex={tabIndex}
            ref={radioRef}
            onFocus={e => {
                e.preventDefault();
                onChange(value);
            }}
            onKeyDown={handleKeys}
        >
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                tabIndex={-1}
                onChange={onChange}
                {...other}
            />
            <span className="radio-label">{label}</span>
            {children && renderContent()}
            <span className="radio-checkmark" />
        </StyledRadio>
    );
};

Radio.propTypes = {
    value: PropTypes.string,
    selectedOption: PropTypes.string,
    autoFocus: PropTypes.bool,
    readOnly: PropTypes.bool,
    name: PropTypes.string,
    tabIndex: PropTypes.number,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]),
    label: PropTypes.string.isRequired
};

Radio.defaultProps = {
    value: '',
    selectedOption: null,
    autoFocus: false,
    readOnly: false,
    tabIndex: 0,
    name: 'radio-group',
    children: null,
    onSubmit: null,
    onChange: () => null
};

export default Radio;
