const submitAddons = addons => ({
    type: 'SUBMIT_ADDONS',
    addons
});

const submitDetails = (
    name,
    email,
    phone,
    password,
    agencyName,
    realEstate,
    mortgage,
    software,
    solar,
    insurance,
    travel,
    automotive,
    support,
    other
) => ({
    type: 'SUBMIT_DETAILS',
    name,
    email,
    phone,
    password,
    agencyName,
    realEstate,
    mortgage,
    software,
    solar,
    insurance,
    travel,
    automotive,
    support,
    other
});

const cleanSignup = () => ({
    type: 'CLEAN_SIGNUP'
});

const setBillingClientReferenceId = agentId => ({
    type: 'SET_BILLING_CLIENT_REFERENCE_ID',
    billingClientReferenceId: agentId
});

const actions = {
    submitAddons,
    submitDetails,
    cleanSignup,
    setBillingClientReferenceId
};

export default actions;
