import cookie from 'cookie';

const getUser = () => {
    const userCookie = cookie.parse(document.cookie)['structurely'];
    let user = null;

    if (userCookie) {
        try {
            user = JSON.parse(userCookie);
        } catch (e) {
            console.error('Failed to get user from cookies\n', e);
        }
    }

    if (user) {
        return user;
    } else {
        return {};
    }
};

export { getUser };
