const submitAddons = addons => ({
    type: 'SUBMIT_ADDONS',
    addons
});

const submitDetails = (
    name,
    email,
    phone,
    password,
    agencyName,
    zendesk,
    realEstate,
    mortgage,
    software,
    solar,
    insurance,
    travel,
    automotive,
    support,
    other
) => ({
    type: 'SUBMIT_DETAILS',
    name,
    email,
    phone,
    password,
    agencyName,
    zendesk,
    realEstate,
    mortgage,
    software,
    solar,
    insurance,
    travel,
    automotive,
    support,
    other
});

const submitPaymentPlan = paymentPlan => ({
    type: 'SUBMIT_PLAN_SELECT',
    paymentPlan
});

const submitZendesk = () => ({
    type: 'SUBMIT_ZENDESK',
    zendesk: true
});

const submitChatbotName = chatbotName => ({
    type: 'SUBMIT_CHATBOT_NAME',
    chatbotName
});

const submitTeamInvites = teamInvites => ({
    type: 'SUBMIT_TEAM_INVITES',
    teamInvites
});

const submitPayment = card => ({
    type: 'SUBMIT_PAYMENT',
    card
});

const cleanPayment = () => ({
    type: 'CLEAN_PAYMENT'
});

const cleanSignup = () => ({
    type: 'CLEAN_SIGNUP'
});

const updateInviteData = invitation => ({
    type: 'UPDATE_INVITE_DATA',
    invitation
});

const actions = {
    submitAddons,
    submitDetails,
    submitPaymentPlan,
    submitChatbotName,
    submitTeamInvites,
    submitPayment,
    submitZendesk,
    cleanPayment,
    cleanSignup,
    updateInviteData
};

export default actions;
