const updateResetPassword = accessToken => ({
    type: 'UPDATE_RESET_PASSWORD',
    accessToken
});

const actions = {
    updateResetPassword
};

export default actions;
