import gql from 'graphql-tag';

const checkEmail = (client, email, cb) => {
    const QUERY_IS_EMAIL_TAKEN = gql`
        query($email: String!) {
            email(email: $email)
        }
    `;

    return client
        .query({
            query: QUERY_IS_EMAIL_TAKEN,
            variables: { email }
        })
        .then(result => {
            if (result.data.email) {
                return email;
            }

            cb(result.data.email);
        });
};

const helpers = {
    checkEmail
};

export default helpers;
