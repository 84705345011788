import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import PlanItem from './PlanItem.jsx';

import core from 'modules/core';

import '../styles.scss';

const { Header, FormAction } = core.components;

class PaymentPlan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentPlan: props.paymentPlan,
            zendesk: props.zendesk
        };

        this.validatePaymentPlan = this.validatePaymentPlan.bind(this);
        this.changePaymentPlan = this.changePaymentPlan.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    static propTypes = {
        paymentPlan: PropTypes.string,
        zendesk: PropTypes.bool,
        history: PropTypes.object.isRequired,
        submitPaymentPlan: PropTypes.func.isRequired,
        submitZendesk: PropTypes.func.isRequired
    };

    static defaultProps = {
        paymentPlan: 'entry',
        zendesk: false
    };

    validatePaymentPlan(paymentPlan) {
        if (!paymentPlan) {
            return 'Please select a plan from our given options before proceeding!';
        }
    }

    changePaymentPlan(paymentPlan) {
        this.setState({ paymentPlan });
    }

    onSubmit() {
        const { showPopover } = core.helpers;
        const { history, submitPaymentPlan, submitZendesk } = this.props;
        const { paymentPlan, zendesk } = this.state;

        let hasErrors = this.validatePaymentPlan(paymentPlan);

        if (!hasErrors) {
            submitPaymentPlan(paymentPlan);
            if(zendesk)
              submitZendesk();

            history.push('/signup/details');
        } else {
            showPopover(hasErrors, 'error');
        }

        return hasErrors;
    }

    render() {
        const { paymentPlan } = this.state;

        return (
            <section className="payment-plan-section">
                <Header
                    title="Select your plan size"
                    subtitle="Introduce me to your team, or hire me for yourself!"
                />
                <div className="form-wrapper">
                    <div className="payment-plan-form">
                        <ul className="plan-list">
                            <PlanItem
                                type="entry"
                                isSelected={paymentPlan === 'entry'}
                                title="Entry"
                                leads={100}
                                price="$299/mo"
                                onClick={() =>
                                    this.changePaymentPlan('entry')
                                }
                            />
                            <PlanItem
                                type="explore"
                                isSelected={paymentPlan === 'explore'}
                                title="Explore"
                                leads={200}
                                price="$499/mo"
                                onClick={() => this.changePaymentPlan('explore')}
                            />
                            <PlanItem
                                type="elevate"
                                isSelected={paymentPlan === 'elevate'}
                                title="Elevate"
                                leads={500}
                                price="$999/mo"
                                onClick={() => this.changePaymentPlan('elevate')}
                            />
                            <PlanItem
                                type="established"
                                isSelected={paymentPlan === 'established'}
                                title="Established"
                                price="$1,499/mo"
                                leads="1,000"
                                onClick={() =>
                                  this.changePaymentPlan('established')
                                }
                            />
                            <PlanItem
                                type="beyond"
                                isSelected={paymentPlan === 'beyond'}
                                title="Beyond"
                                price="Contact Us"
                                leads="1,000+"
                                onClick={() =>
                                    (window.location.href =
                                        'https://www.structurely.com/contact/')
                                }
                            />
                        </ul>
                    </div>
                    <div className="info-wrapper">
                      <h1> ALL PLANS INCLUDE </h1>
                    </div>
                    <div className="plan-features">
                        <ul>
                            <li> AI Text Assistant </li>
                            <li> AI Email Assistant </li>
                            <li> New and existing contacts </li>
                            <li> 24/7/365 contact engagement </li>
                            <li> 12+ months follow-up </li>
                            <li> Appointment scheduling </li>
                            <li> No setup fee </li>
                            <li> 20+ CRM integrations </li>
                            <li> Bilingual in Spanish + English </li>
                            <li> Mobile app: take over conversation at anytime </li>
                            <li> Unlimited custom scripts </li>
                            <li> Copied contact routing from CRM </li>
                            <li> Dedicated account support rep to help </li>
                        </ul>
                    </div>
                    <FormAction loading={false} onSubmit={this.onSubmit}>
                        Hire Your AI Assistant
                    </FormAction>
                    <div className="form-links">
                        <p className="form-link">
                            Already have an account?{' '}
                            <Link to="/login">Sign In</Link>
                        </p>
                        <p className="form-link">or</p>
                        <p className="form-link">
                            Forgot your password?{' '}
                            <Link to="/reset/request">Reset Password</Link>
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}

export default PaymentPlan;
