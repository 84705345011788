import gql from 'graphql-tag';

const AUTHENTICATE_USER = gql`
    query($email: String!, $password: String!) {
        authentication(email: $email, password: $password) {
            agentId
            teamId
            brokerageId
            isTeamLead
            isBroker
            authJwt
            authToken
            isZendesk
        }
    }
`;

const queries = {
    AUTHENTICATE_USER
};

export default queries;