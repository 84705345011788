import React from 'react';
import ReactDOM from 'react-dom';

import App from 'modules/core/components/App.jsx';
import store from './store';
import client from './client';
import { getUser } from './storage';
import * as serviceWorker from './serviceWorker';

const { agentId, authJwt } = getUser();

if (agentId && authJwt && process.env.NODE_ENV !== 'development') {
    window.location = process.env.REACT_APP_HOMECHAT_URL;
} else {
    ReactDOM.render(
        React.createElement(App, {
            store,
            client
        }),
        document.getElementById('root')
    );
}

serviceWorker.unregister();
