const initialState = {
    name: '',
    email: '',
    phone: '',
    password: '',
    zendesk: false,
    realEstate: false,
    mortgage: false,
    software: false,
    solar: false,
    insurance: false,
    travel: false,
    automotive: false,
    other: false,
    paymentPlan: 'entry',
    agencyName: '',
    chatbotName: 'Aisa',
    addons: [],
    teamInvites: [
        {
            id: 0,
            email: '',
            name: ''
        }
    ],
    invitation: {
        accessToken: '',
        expires: '',
        teamId: '',
        brokerageId: '',
        inviteType: ''
    },
    card: {
        token: '',
        lastFour: '',
        zip: '',
        exp: {
            month: '',
            year: ''
        }
    }
};

const signup = (state = initialState, action) => {
    switch (action.type) {
        case 'SUBMIT_DETAILS':
            return {
                ...state,
                name: action.name,
                email: action.email,
                phone: action.phone,
                password: action.password,
                agencyName: action.agencyName,
                zendesk: action.zendesk,
                realEstate: action.realEstate,
                mortgage: action.mortgage,
                software: action.software,
                solar: action.solar,
                insurance: action.insurance,
                travel: action.travel,
                automotive: action.automotive,
                support: action.support,
                other: action.other
            };
        case 'SUBMIT_ADDONS':
            return {
                ...state,
                addons: action.addons
            };
        case 'SUBMIT_PLAN_SELECT':
            return {
                ...state,
                paymentPlan: action.paymentPlan
            };
        case 'SUBMIT_CHATBOT_NAME':
            return {
                ...state,
                chatbotName: action.chatbotName
            };
        case 'SUBMIT_TEAM_INVITES':
            return {
                ...state,
                teamInvites: action.teamInvites
            };
        case 'SUBMIT_PAYMENT':
            return {
                ...state,
                card: action.card
            };
        case 'SUBMIT_ZENDESK':
            return {
                ...state,
                zendesk: action.zendesk
            };
        case 'CLEAN_PAYMENT':
            return {
                ...state,
                card: {
                    token: '',
                    lastFour: '',
                    zip: '',
                    exp: {
                        month: '',
                        year: ''
                    }
                }
            };
        case 'CLEAN_SIGNUP':
            return {
                ...initialState
            };
        case 'UPDATE_INVITE_DATA':
            return {
                ...state,
                invitation: action.invitation,
                email: action.invitation.email
            };
        default:
            return state;
    }
};

const reducer = {
    signup
};

export default reducer;
