import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Intercom from 'react-intercom';
import { Switch } from 'react-router-dom';
import { Route, Redirect } from 'react-router';
import { NotificationContainer } from 'react-notifications';

import helpers from '../helpers';

import LoginLayout from 'modules/login/containers/LoginLayout.js';
import SignupLayout from 'modules/signup/containers/SignupLayout.js';
import ResellerSignupContainer from 'modules/reseller/containers/ResellerSignupContainer.jsx';
import SubscribeLayout from 'modules/subscribe/containers/SubscribeLayout.js';
import PasswordLayout from 'modules/password/containers/PasswordLayout.js';
import IntegrationsLayout from 'modules/integrations/components/IntegrationsLayout.jsx';

class MainLayout extends Component {
    static propTypes = {
        redirectUrl: PropTypes.string,
        history: PropTypes.object.isRequired,
        updateRedirectUrl: PropTypes.func.isRequired
    };

    static defaultProps = {
        redirectUrl: ''
    };

    componentDidMount() {
        this.getRedirectUrl();
    }

    getRedirectUrl() {
        const { redirectUrl, updateRedirectUrl } = this.props;
        const { getUrlVariable } = helpers;

        if (!redirectUrl) {
            let r = getUrlVariable('r');

            if (r) {
                updateRedirectUrl(decodeURIComponent(r));
            }
        }
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route path="/login" component={LoginLayout} />
                    <Route path="/authentication" component={LoginLayout} />
                    <Route path="/reset" component={PasswordLayout} />
                    <Route path="/signup" component={SignupLayout} />
                    <Route path="/subscribe" component={SubscribeLayout} />
                    <Route
                        path="/reseller-signup"
                        component={ResellerSignupContainer}
                    />
                    <Route
                        path="/integrations"
                        component={IntegrationsLayout}
                    />
                    <Redirect from="/" to="/login" />
                </Switch>
                <Intercom appID={process.env.REACT_APP_INTERCOM_APP_ID} />
                <NotificationContainer />
            </div>
        );
    }
}

export default MainLayout;
