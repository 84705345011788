import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';

const link = createHttpLink({
    uri: process.env.REACT_APP_DATALAYER_URL,
    credentials: 'include'
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link
});

export default client;
