import React from 'react';
import PropTypes from 'prop-types';

const Billing = ({ email, billingClientReferenceId, history }) => {
    if (!billingClientReferenceId) {
        console.warn('Lead got to here without agent id');
        history.push('/subscribe/details');
        return <div></div>;
    }

    return (
        <div>
            <stripe-pricing-table
                pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
                publishable-key={process.env.REACT_APP_STRIPE_KEY}
                customer-email={email}
                client-reference-id={billingClientReferenceId}
            ></stripe-pricing-table>
        </div>
    );
};

Billing.propTypes = {
    email: PropTypes.string.isRequired,
    billingClientReferenceId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired
};

export default Billing;
