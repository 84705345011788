import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Header extends Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        notice: PropTypes.string
    };

    static defaultProps = {
        title: '',
        subtitle: '',
        notice: ''
    };

    renderTitle() {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return <h1 className="title">{title}</h1>;
    }

    renderSubtitle() {
        const { subtitle } = this.props;

        if (!subtitle) {
            return null;
        }

        return <div className="subtitle">{subtitle}</div>;
    }

    renderNotice() {
        const { notice } = this.props;

        if (!notice) {
            return null;
        }

        return (
            <div className="notice-wrapper">
                <p className="notice">{notice}</p>
            </div>
        );
    }

    render() {
        return (
            <header className="header">
                <a
                    href="https://structurely.com"
                    rel="noopener noreferrer external"
                >
                    <img
                        src="/img/structurely-green-logo.png"
                        title="Structurely Logo"
                        width="180px"
                        alt="Structurely Logo"
                        role="presentation"
                    />
                </a>
                {this.renderTitle()}
                {this.renderSubtitle()}
                {this.renderNotice()}
            </header>
        );
    }
}

export default Header;
