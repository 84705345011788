import gql from 'graphql-tag';

const MUTATE_SIGNUP_FOR_STRUCTURELY = gql`
    mutation signUp($agent: AgentInputType!, $useHatchIsaScripts: Boolean!) {
        signupForStructurely(
            agent: $agent
            useHatchIsaScripts: $useHatchIsaScripts
        ) {
            agentId
        }
    }
`;

const mutations = {
    MUTATE_SIGNUP_FOR_STRUCTURELY
};

export default mutations;
