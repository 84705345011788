import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Spinner from './Spinner.jsx';

const FormNavigation = ({ onSubmit, btnText, loading, prev, next }) => {
    const renderBtnValue = () => {
        let value = btnText;

        if (loading) {
            value = <Spinner sm />;
        }

        return value;
    };

    const renderSkip = () => {
        if (!next) return null;

        return (
            <Link className="nav-link next-link" to={next}>
                Skip
                <span className="icon icons8-chevron-right" />
            </Link>
        );
    };

    return (
        <div className="form-navigation">
            {!!prev && (
                <Link className="nav-link prev-link" to={prev}>
                    <span className="icon icons8-chevron-left" />
                    Back
                </Link>
            )}
            <button onClick={onSubmit} className="btn success-btn">
                {renderBtnValue()}
            </button>
            {renderSkip()}
        </div>
    );
};

FormNavigation.defaultProps = {
    prev: '',
    next: '',
    btnText: '',
    loading: false
};

FormNavigation.propTypes = {
    prev: PropTypes.string,
    next: PropTypes.string,
    btnText: PropTypes.string,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired
};

export default FormNavigation;
