import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PaymentPlan from '../components/PaymentPlan.jsx';

import actions from '../../../actions.js';

/** Redux Container **/
const mapStateToProps = state => {
    const { paymentPlan } = state.signup;

    return {
        paymentPlan
    };
};

const mapDispatchToProps = dispatch => {
    const { submitPaymentPlan, submitZendesk } = actions;

    return bindActionCreators(
        {
            submitPaymentPlan,
            submitZendesk
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentPlan);
