const initialState = {
    redirectUrl: ''
};

const core = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_REDIRECT_URL':
            return {
                ...state,
                redirectUrl: action.redirectUrl
            };
        default:
            return state;
    }
};

export { core };
