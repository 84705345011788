import pako from 'pako';
import base64url from 'base64url';
import { NotificationManager } from 'react-notifications';

export const getUrlVariable = variableName => {
    const query = window.location.hash.split('?')[1];

    if (query) {
        const vars = query.split('&');

        for (let variable of vars) {
            let pair = variable.split('=');

            if (pair[0] === variableName) {
                return pair[1];
            }
        }
    }

    return null;
};

export const parseToken = accessToken => {
    let isCompressed = false;

    if (accessToken.charAt(0) === '.') {
        isCompressed = true;
        accessToken = accessToken.substr(1);
    }

    accessToken = accessToken.split('.')[0];

    /**
     * Convert base64url to base64 (Note: base64url.decode is not working,
     * as is typical of a library who banks on solving the whole problem
     * of decoding base64url)
     **/
    let base64data = base64url.toBase64(accessToken);
    let decodedData = window.atob(base64data);

    if (isCompressed) {
        /**
         * Decode the zlib data by turning the string converted
         * from base64url to a character code int array,
         * then uncompress with pako and turn back into as string.
         **/
        decodedData = decodedData.split('').map(function(e) {
            return e.charCodeAt(0);
        });

        let binData = new Uint8Array(decodedData);
        let data = pako.inflate(binData);
        return JSON.parse(
            String.fromCharCode.apply(null, new Uint16Array(data))
        );
    }

    return JSON.parse(decodedData);
};

const loadExternalScript = src =>
    new Promise((resolve, reject) => {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', () => {
            resolve();
        });
        script.addEventListener('error', e => {
            reject(e);
        });
        document.body.appendChild(script);
    });

const getFormattedPhoneNumber = phone => {
    if (!phone) {
        return '';
    }

    let baseNumber = phone.replace(/\D/g, '');

    if (!baseNumber) {
        return '';
    } else if (baseNumber.length > 10) {
        baseNumber = baseNumber.substring(0, 10);
    }

    let areaCode = '';
    let firstDigits = '';
    let lastDigits = '';

    if (baseNumber.length < 3) {
        areaCode = baseNumber.substring(0, baseNumber.length);
        return `(${areaCode}`;
    } else if (baseNumber.length >= 3 && baseNumber.length < 7) {
        areaCode = baseNumber.substring(0, 3);
        firstDigits = baseNumber.substring(3, baseNumber.length);
        return `(${areaCode}) ${firstDigits}`;
    } else if (baseNumber.length >= 7) {
        areaCode = baseNumber.substring(0, 3);
        firstDigits = baseNumber.substring(3, 6);
        lastDigits = baseNumber.substring(6, baseNumber.length);
        return `(${areaCode}) ${firstDigits}-${lastDigits}`;
    } else {
        return baseNumber;
    }
};

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

const showPopover = (message, type) => {
    if (type === 'success') {
        NotificationManager.success(message, 'Success', 15000);
    } else {
        NotificationManager.error(message, 'Error', 15000);
    }
};

const showError = error => {
    if (error && typeof error === 'object' && error.graphQLErrors) {
        error.graphQLErrors.forEach(error => {
            showPopover(error.message, 'error');
        });
    } else {
        showPopover(error, 'error');
    }
};

const helpers = {
    getUrlVariable,
    parseToken,
    loadExternalScript,
    getFormattedPhoneNumber,
    capitalize,
    showPopover,
    showError
};

export default helpers;
