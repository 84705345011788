import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import PasswordLayout from '../components/PasswordLayout.jsx';

import actions from '../actions.js';

/** Redux Container **/
const mapStateToProps = state => {
    const { accessToken } = state.reset.resetPassword;

    return {
        accessToken
    };
};

const mapDispatchToProps = dispatch => {
    const { updateResetPassword } = actions;

    return bindActionCreators(
        {
            updateResetPassword
        },
        dispatch
    );
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PasswordLayout)
);
