import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Footer extends Component {
    static propTypes = {
        title: PropTypes.string,
        quote: PropTypes.string,
        author: PropTypes.string,
        profileUrl: PropTypes.string
    };

    static defaultProps = {
        title: '',
        quote: '',
        author: '',
        profileUrl: ''
    };

    renderFooterQuote() {
        const { title, quote, author, profileUrl } = this.props;

        if (!title) {
            return null;
        }

        return (
            <div className="footer-quotation">
                <div className="footer-header">{title}</div>
                <div className="footer-quote">{quote}</div>
                <div className="footer-source">
                    {author}
                    <img
                        src={profileUrl}
                        width="30px"
                        height="30px"
                        alt="Structurely Logo"
                        role="presentation"
                    />
                </div>
            </div>
        );
    }

    render() {
        return <footer className="footer">{this.renderFooterQuote()}</footer>;
    }
}

export default Footer;
