import actions from './actions.js';
import helpers from './helpers.js';
import * as reducer from './reducer.js';
import components from './components';

const core = {
    actions,
    helpers,
    reducer,
    components
};

export default core;
