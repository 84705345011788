import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import Billing from '../containers/Billing';
import ProfileDetails from '../containers/ProfileDetails';

import Header from './Header';
import Footer from './Footer';

const Success = () => {
    return (
        <section className="details-section">
            <div
                className="form-wrapper"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <div style={{ minHeight: 96, minWidth: 96 }}>
                    <CheckCircleIcon
                        style={{ fill: 'Green' }}
                    ></CheckCircleIcon>
                </div>
                <p style={{ fontSize: 36, paddingTop: 18 }}>
                    Thanks for Subscribing!
                </p>
                <p style={{ marginTop: 10, fontSize: 16 }}>
                    A payment to Structurely will show up on your statement.
                </p>
                <div style={{ marginTop: 10, fontSize: 18, paddingTop: 18 }}>
                    <div style={{ fill: 'Blue' }}>
                        <Link to={'/login'}>
                            <button className="btn success-btn">
                                {' '}
                                Login to Structurely
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

class SubscribeLayout extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    render() {
        return (
            <main className="signup-layout" role="main">
                <div>
                    <Header></Header>
                </div>
                <Switch>
                    <Route exact path="/subscribe" component={ProfileDetails} />
                    <Route
                        path="/subscribe/details"
                        component={ProfileDetails}
                    />
                    <Route path="/subscribe/billing" component={Billing} />
                    <Route path="/subscribe/success" component={Success} />
                </Switch>
                <div>
                    <Footer></Footer>
                </div>
            </main>
        );
    }
}

export default SubscribeLayout;
