import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showError: false
        };

        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    static propTypes = {
        value: PropTypes.string,
        label: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        disabled: PropTypes.bool,
        autoFocus: PropTypes.bool,
        autocomplete: PropTypes.string,
        inputMode: PropTypes.string,
        className: PropTypes.string,
        validator: PropTypes.func,
        onEnter: PropTypes.func,
        children: PropTypes.element,
        onChange: PropTypes.func.isRequired,
        'data-form-type': PropTypes.string
    };

    static defaultProps = {
        value: '',
        label: '',
        id: '',
        type: 'text',
        disabled: false,
        autoFocus: false,
        className: '',
        validator: () => null,
        onEnter: () => null
    };

    onBlur() {
        const { validator, value } = this.props;

        if (validator(value)) {
            this.setState({ showError: true });
        }
    }

    onChange(e) {
        const { onChange, validator } = this.props;

        onChange(e);

        if (!validator(e.currentTarget.value)) {
            this.setState({ showError: false });
        }
    }

    renderValue() {
        const {
            id,
            value,
            type,
            disabled,
            autoFocus,
            autocomplete,
            inputMode,
            name,
            onEnter
        } = this.props;

        return (
            <input
                className="input"
                id={id ? `${id}-input` : undefined}
                name={name}
                value={value}
                type={type}
                inputMode={inputMode || undefined}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onKeyDown={e => (e.keyCode === 13 ? onEnter() : null)}
                disabled={disabled}
                autoFocus={autoFocus}
                autoComplete={autocomplete}
                data-form-type={this.props['data-form-type']}
            />
        );
    }

    renderLabel() {
        const { id, label } = this.props;

        if (!label) return null;

        return (
            <label className="label" htmlFor={id}>
                {label}
            </label>
        );
    }

    renderError() {
        const { showError } = this.state;
        const { validator, value } = this.props;

        if (!showError) return null;

        let errorMsg = validator(value);

        return (
            <div className="error-wrapper">
                <span className="error">{errorMsg}</span>
            </div>
        );
    }

    getClassName() {
        const { value, className } = this.props;
        const { showError } = this.state;

        let errorClass = showError ? 'error-input' : '';
        let labelClass = value ? 'label-top' : '';
        return `${className} input-container ${labelClass} ${errorClass}`;
    }

    render() {
        const { id, children } = this.props;

        return (
            <div id={id} className={this.getClassName()}>
                {this.renderValue()}
                {this.renderLabel()}
                {this.renderError()}
                {children}
            </div>
        );
    }
}

export default Input;
