import actions from './actions';
import reducer from './reducer.js';
import helpers from './helpers.js';

const signup = {
    actions,
    reducer,
    helpers
};

export default signup;
