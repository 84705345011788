import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import MainLayout from '../components/MainLayout.jsx';

import signup from 'modules/signup';
import actions from '../actions.js';

/** Redux Container **/
const mapStateToProps = state => {
    const { redirectUrl } = state.core;
    const { invitation } = state.signup;

    return {
        redirectUrl,
        invitation
    };
};

const mapDispatchToProps = dispatch => {
    const { updateRedirectUrl } = actions;
    const { updateInviteData } = signup.actions;

    return bindActionCreators(
        {
            updateInviteData,
            updateRedirectUrl
        },
        dispatch
    );
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MainLayout)
);
