import { graphql, compose } from 'react-apollo';

import LionDeskLayout from '../components/LionDeskLayout.jsx';

import mutations from '../mutations.js';

/** GraphQL Container **/
const { MUTATE_INTEGRATE_LION_DESK } = mutations;

const withIntegrateLionDesk = graphql(MUTATE_INTEGRATE_LION_DESK, {
    props: ({ mutate }) => ({
        integrateLionDesk(agencyName, defaultChatbotName, officeLocation) {
            return mutate({
                variables: {
                    agencyName,
                    defaultChatbotName,
                    officeLocation
                }
            });
        }
    })
});

export default compose(withIntegrateLionDesk)(LionDeskLayout);
