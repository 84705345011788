import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import '../styles.scss';
import queries from '../queries.js';
import core from 'modules/core';
const { AUTHENTICATE_USER } = queries;
const { Header, Footer, Input, FormAction } = core.components;

class LoginLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loading: false
        };

        this.validateEmail = this.validateEmail.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    static propTypes = {
        email: PropTypes.string,
        notice: PropTypes.string,
        redirectUrl: PropTypes.string,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        client: PropTypes.object.isRequired,
        validateUser: PropTypes.func.isRequired
    };

    static defaultProps = {
        email: '',
        notice: '',
        redirectUrl: ''
    };

    componentDidMount() {
        const { match } = this.props;

        if (match.path === '/authentication') {
            this.validateUser();
        }
    }

    validateUser() {
        const { getUrlVariable, showPopover } = core.helpers;
        const { validateUser, history } = this.props;
        const accessToken = getUrlVariable('accessToken');

        if (accessToken) {
            validateUser(accessToken)
                .then(result => {
                    history.push('/login');

                    if (
                        result &&
                        result.data &&
                        result.data.validateAgent.status
                    ) {
                        showPopover(
                            'Your account has been validated',
                            'success'
                        );
                    } else {
                        showPopover('Failed to validate account', 'error');
                    }
                })
                .catch(error => {
                    history.push('/login');

                    if (error && error.graphQLErrors) {
                        error.graphQLErrors.forEach(error => {
                            showPopover(error.message, 'error');
                        });
                    } else {
                        showPopover('Failed to validate account', 'error');
                    }
                });
        } else {
            history.push('/login');
            showPopover('Failed to validate account', 'error');
        }
    }

    validateEmail(email) {
        if (!email) {
            return 'The email field is required';
        } else {
            let re = /\S+@\S+\.\S+/;

            if (!re.test(email)) {
                return 'The email is invalid, please use a valid email address.';
            }
        }
    }

    validatePassword(password) {
        if (!password) {
            return 'The password field is required';
        }
    }

    changeEmail(e) {
        this.setState({ email: e.currentTarget.value });
    }

    changePassword(e) {
        this.setState({ password: e.currentTarget.value });
    }

    onSubmit() {
        const { showPopover } = core.helpers;
        const { client, redirectUrl } = this.props;
        const { email, password } = this.state;

        let hasErrors =
            this.validateEmail(email) || this.validatePassword(password);

        if (!hasErrors) {
            const handleAuthentication = () =>
                client
                    .query({
                        query: AUTHENTICATE_USER,
                        variables: { email, password }
                    })
                    .then(result => {
                        this.setState({ loading: false });

                        if (
                            result.data &&
                            result.data.authentication &&
                            result.data.authentication.authJwt &&
                            result.data.authentication.authToken
                        ) {
                            const {
                                agentId,
                                teamId,
                                brokerageId,
                                isTeamLead,
                                isBroker,
                                isZendesk,
                                authJwt,
                                authToken
                            } = result.data.authentication;

                            let authData = {
                                agentId,
                                teamId,
                                brokerageId,
                                isTeamLead,
                                isBroker,
                                authJwt,
                                authToken,
                                isZendesk,
                                isIndependentAgent: !teamId && !brokerageId
                            };

                            let data =
                                'structurely=' + JSON.stringify(authData) + ';';
                            let path = 'Path=/;';
                            let domain =
                                'Domain=' +
                                process.env.REACT_APP_COOKIE_URL +
                                ';';

                            document.cookie = `${data} ${path} ${domain}`;

                            if (redirectUrl) {
                                window.location = redirectUrl;
                            } else {
                                window.location =
                                    process.env.REACT_APP_HOMECHAT_URL;
                            }
                        } else {
                            showPopover(
                                'Email or password provided was invalid',
                                'error'
                            );
                        }
                    })
                    .catch(error => {
                        this.setState({ loading: false });
                        if (error && error.graphQLErrors) {
                            error.graphQLErrors.forEach(error => {
                                showPopover(error.message, 'error');
                            });
                        } else {
                            this.setState({ loading: false });
                            showPopover(
                                'Email or password provided was invalid',
                                'error'
                            );
                        }
                    });

            this.setState({ loading: true }, handleAuthentication);
        } else {
            showPopover(hasErrors, 'error');
        }
    }

    renderHeader() {
        const { notice } = this.props;

        if (notice) {
            return <Header notice={notice} />;
        } else {
            return <Header subtitle="Login to your account" />;
        }
    }

    render() {
        const { email, password, loading } = this.state;

        return (
            <main className="login-layout" role="main">
                <section className="login-section">
                    {this.renderHeader()}
                    <div className="form-wrapper">
                        <div className="login-form form">
                            <Input
                                id="email-input"
                                className="email-input"
                                label="Email"
                                value={email}
                                onChange={this.changeEmail}
                                onEnter={this.onSubmit}
                                autoFocus={true}
                            />
                            <Input
                                id="password-input"
                                className="password-input"
                                label="Password"
                                type="password"
                                value={password}
                                onChange={this.changePassword}
                                onEnter={this.onSubmit}
                            />
                            <FormAction
                                loading={loading}
                                onSubmit={this.onSubmit}
                            >
                                Login
                            </FormAction>
                        </div>
                        <div className="form-links">
                            <p className="form-link">
                                Don't have an account?{' '}
                                <Link to="/subscribe">Sign Up</Link>
                            </p>
                            <p className="form-link">or</p>
                            <p className="form-link">
                                Forgot your password?{' '}
                                <Link to="/reset/request">Reset Password</Link>
                            </p>
                        </div>
                    </div>
                    <Footer />
                </section>
            </main>
        );
    }
}

export default LoginLayout;
