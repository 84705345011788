const initialState = {
    resetPassword: {
        accessToken: ''
    }
};

const reset = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_RESET_PASSWORD':
            return {
                ...state,
                resetPassword: {
                    accessToken: action.accessToken
                }
            };
        default:
            return state;
    }
};

const reducer = {
    reset
};

export default reducer;
